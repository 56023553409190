import React, {useState} from 'react'
import Form from 'components/Form'
import Text from 'components/Text'
import {useHistory} from 'react-router-dom'
import useLocalization from 'hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import {SiteState, TimeseriesKind} from 'domain/ISite'
import useTheme from 'hooks/useTheme'
import {toLower, startCase} from 'lodash-es'
import {getHumanReadableState} from 'helpers/site'
import {Container, FormContainer, StyledFormSelect} from '../Layout'
import useServices from 'hooks/useServices'
import useStore from 'hooks/useStore'
import {CertificateTech} from 'domain/IRegoCertificate'
import {partyAtom} from 'atoms/party'
import {useAtom, useAtomValue} from 'jotai'
import {ICreateOnbSellerSiteReq} from 'domain/ICreateOnbSiteReq'
import {LocationBox} from 'components/Platform/Portfolio/Site/LocationSearch'
import {sellerOnboardingAtom} from 'atoms/onboarding'
import {Heading} from '../Heading'
import {getOnboardSite} from 'domain/ISellerOnboardState'
import {StyledFormButton, StyledFormInput, StyledLocationSearch} from '../Layout/FormComponents'
import {MeterRefKind, ProfileUploadKind} from 'services/V2ApiErrorMapper'
import CenteredLoader from 'components/CenteredLoader'
import SiteConsumptionUpload from './SiteConsumptionUpload'
import Button from 'components/Button'
import useAlerts from 'hooks/useAlerts'

interface IProps extends React.PropsWithChildren {
  openSkipDialog?: () => void
}

const SetupSitesForm: React.FC<IProps> = ({openSkipDialog}) => {
  const [uploadType, setUploadType] = useState(TimeseriesKind.BUILDER)
  const theme = useTheme()
  const {translate} = useLocalization()
  const {v2ContentService} = useServices()
  const [party, setParty] = useAtom(partyAtom)
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(sellerOnboardingAtom)
  const {addError} = useAlerts()
  const history = useHistory()
  const isFileUpload = uploadType === TimeseriesKind.FILE_UPLOAD

  const handleSubmit = async (formData, {setError}) => {
    const {location, file, builder, interval, volumePercent} = formData

    //TODO: proper date handling
    const date = formData.operationsStart.substring(0, 10)
    const req: ICreateOnbSellerSiteReq = {
      name: formData.name,
      technology: formData.technology,
      state: formData.state,
      prodCapacityMwac: +formData.prodCapacityMwac,
      operationsStart: date,
      lifetimeYears: +formData.lifetimeYears,
      meterReference: formData.meterReference,
      placeId: location?.placeId,
      interval: {
        month: interval.month,
        year: +interval.year,
      },
      volumePercent: +volumePercent,
      timeseries: {
        kind: isFileUpload ? TimeseriesKind.FILE_UPLOAD : TimeseriesKind.BUILDER,
        payload: {
          hourlyVolumes: isFileUpload ? undefined : builder.hourlyVolumes,
          attachmentId: isFileUpload ? file.id : undefined,
        },
      },
    }

    try {
      const res = await v2ContentService.createSellerOnbSite(party.id, req)
      const updated = {
        ...party,
        onboardingStatus: res.onboardingStatus,
      }
      setParty(updated)
      history.push('/broker/onboarding/create-product')
    } catch (e) {
      const kind = e?.error?.kind
      if (kind in ProfileUploadKind) {
        setError('file', 'file', e.message)
        addError(e.message)
      } else if (kind in MeterRefKind) {
        setError('meterReference', 'meterReference', e.message)
        addError(e.message)
      } else {
        addError('Failed to save site', e?.correlationId, e?.message)
      }
    }
  }

  if (onbError) {
    return (
      <Container>
        <Heading size="large" color={theme.colors.common.white}>
          {translate('Something went wrong')}
        </Heading>
      </Container>
    )
  }

  if (onbLoading) {
    return (
      <Container>
        <CenteredLoader size="medium" color={theme.colors.common.white} />
      </Container>
    )
  }

  const site = getOnboardSite(onboardResult)
  const isEdit = site !== null

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit} fillWidth={true} submissionFeedback={null} defaultValues={site}>
        <Text size="xxxlarge" color={theme.colors.common.black} margin={{bottom: 2}} data-cy="site-step-title">
          1. Set up site
        </Text>
        <StyledFormInput
          $small
          name="name"
          label={translate('Site name')}
          placeholder={translate('Insert site name')}
          required
          data-cy="name"
          defaultValue={''}
        />
        <StyledFormSelect
          name="technology"
          label={translate('Technology')}
          required
          placeholder={translate('Select one..')}
          options={Object.values(CertificateTech).map(tech => ({
            value: tech,
            label: translate(startCase(toLower(tech))),
          }))}
          data-cy="tech"
        />
        <StyledFormSelect
          name="state"
          label={translate('Status')}
          required
          placeholder={translate('Select one..')}
          options={Object.keys(SiteState).map(state => ({
            value: state,
            label: getHumanReadableState(state as SiteState, translate),
          }))}
          data-cy="state"
        />
        <StyledFormInput
          $small
          name="prodCapacityMwac"
          label={translate('Nameplate capacity, MW')}
          placeholder={translate('Insert capacity as number')}
          type="number"
          required
          data-cy="capacity"
          defaultValue={''}
        />
        <StyledFormInput
          $small
          name="operationsStart"
          label={translate('Operations start')}
          type="date"
          placeholder={translate('Insert date')}
          required
          data-cy="operationsStart"
          defaultValue={''}
        />
        <StyledFormInput
          $small
          name="lifetimeYears"
          min={1}
          max={20}
          label={translate('Planned lifetime, in years')}
          type="number"
          placeholder={translate('Insert years as number')}
          required
          data-cy="lifetimeYears"
          defaultValue={''}
        />
        <StyledFormInput
          $small
          name="meterReference"
          minLength={6}
          maxLength={25}
          label={translate('Meter reference number')}
          placeholder={translate('Insert meter reference number')}
          required
          data-cy="meterReference"
          defaultValue={''}
        />

        <LocationBox data-cy="location-select">
          <Text size="msmall" uppercase>
            {translate('Location')}
          </Text>
          <StyledLocationSearch
            $small
            location={site?.location}
            variant="contrast"
            placeholder={translate('Start typing the address(based on Google Map)')}
            required
          />
        </LocationBox>
        <SiteConsumptionUpload uploadType={uploadType} setUploadType={setUploadType} />

        <HorizStack justify="space-between" margin={{top: 1}} pad={{bottom: 0.5}}>
          <Button large variant="outlined" onClick={openSkipDialog} contrast data-cy="skip">
            <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
              {translate('Skip')}
            </Text>
          </Button>
          <StyledFormButton data-cy="continue">{isEdit ? translate('Update') : translate('Continue')}</StyledFormButton>
        </HorizStack>
      </Form>
    </FormContainer>
  )
}

export default SetupSitesForm
