import React from 'react'
import Select from '../../Select'
import useStoreData from '../../../hooks/useStoreData'
import {getChangeEventValue} from '../../../helpers/misc'
import useStore from '../../../hooks/useStore'
import useTheme from 'hooks/useTheme'
import {useHistory} from 'react-router-dom'
import {getPlatformUrl} from 'helpers/party'

const PartySelect: React.FC<React.PropsWithChildren> = () => {
  const {profileStore} = useStore()
  const history = useHistory()
  const theme = useTheme()
  const partyMemberships = useStoreData(store => store.profileStore.partyMemberships)

  if (partyMemberships.length === 1) {
    return null
  }

  const handlePartyMemberClick = e => {
    const partyMemberId = getChangeEventValue(e).toString()
    const party = partyMemberships.find(partyMember => partyMember.id === +partyMemberId).party

    profileStore.selectedPartyMemberId = partyMemberId // App.tsx effect will update party atom
    localStorage.setItem('currentPartyMemberId', partyMemberId)
    history.push(getPlatformUrl(party?.partyRole, theme))
  }

  return (
    <Select
      data-cy="party-select"
      margin={{right: 2}}
      style={{border: `1px solid ${theme.colors.secondary}`, minWidth: 210}}
      value={profileStore.selectedPartyMemberId || undefined}
      options={
        partyMemberships.length > 0
          ? partyMemberships.map(partyMember => ({
              label: partyMember.party?.name,
              value: partyMember.id,
            }))
          : []
      }
      onChange={e => handlePartyMemberClick(e)}
    />
  )
}

export default PartySelect
