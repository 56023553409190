import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import useServices from '../../../hooks/useServices'
import Box from '../../Box'
import Form from '../../Form'
import FormInput from '../../Form/FormInput'
import FormSubmitButton from '../../Form/FormSubmitButton'
import Label, {LabelWithIcon} from '../../Label'
import LocationSearch from '../Portfolio/Site/LocationSearch'
import Skeleton from '@mui/material/Skeleton'
import {useAtomValue} from 'jotai'
import {companyInfoAtom} from 'atoms/company'
import useStore from 'hooks/useStore'
import {ICompanyInformation} from 'domain/ICompanyInformation'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useAlerts from 'hooks/useAlerts'
import {countryVatRegex} from 'helpers/location'

const CompanyInformationForm: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {v2ContentService} = useServices()
  const {profileStore} = useStore()
  const {addSuccess, addError} = useAlerts()
  const {data: companyInfo, loading} = useAtomValue(companyInfoAtom)

  const handleSubmit = async (changedParty: ICompanyInformation) => {
    try {
      const result = await v2ContentService.saveCompanyInformation({
        id: companyInfo?.id,
        name: changedParty?.name,
        registrationCode: changedParty?.registrationCode,
        vatCode: changedParty?.vatCode,
      })

      const partyMember = profileStore.getPartyMemberByPartyId(companyInfo?.id)
      profileStore.setPartyMember({
        ...partyMember,
        party: {
          ...partyMember?.party,
          name: result?.name,
        },
      })

      addSuccess(translate('Company information saved'))
    } catch (error) {
      addError(translate('Unable to save company information'), error?.correlationId, error?.message)
    }
  }

  if (loading) {
    return (
      <Box>
        <Box margin={{bottom: 3}}>
          <Skeleton width="150px" height="24px" />
        </Box>

        <Box margin={{bottom: 2}}>
          <Skeleton width="120px" height="20px" />
          <Skeleton height="40px" />
        </Box>

        <Box margin={{bottom: 2}}>
          <Skeleton width="150px" height="20px" />
          <Skeleton height="40px" />
        </Box>

        <Box margin={{bottom: 2}}>
          <Skeleton width="140px" height="20px" />
          <Skeleton height="40px" />
        </Box>

        <Box margin={{bottom: 2}}>
          <Skeleton width="100px" height="20px" />
          <Skeleton height="40px" />
        </Box>

        <Box margin={{top: 2}}>
          <Skeleton width="120px" height="40px" />
        </Box>
      </Box>
    )
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={companyInfo} submissionFeedback={null}>
      <Box margin={{bottom: 3}}>
        <Label text={translate('Account type')}>{translate('Corporate account')}</Label>
      </Box>

      <FormInput data-cy="company-name" label={translate('Company name')} name="name" required />

      <FormInput data-cy="company-registration-code" label={translate('Company reg. number')} name="registrationCode" />

      <FormInput
        data-cy="company-vat-code"
        required
        validate={value => {
          if (!countryVatRegex.test(value)) {
            return translate('Must be a valid VAT number')
          }

          return true
        }}
        label={translate('Company vat. number')}
        name="vatCode"
      />

      <Box direction="row" align="center">
        <LabelWithIcon
          text={translate('Address')}
          Icon={<InfoOutlinedIcon sx={{height: '16px', width: '16px'}} />}
          tooltip={translate('Address is currently not changable')}
        />
      </Box>
      <LocationSearch location={companyInfo?.location} variant="primary" isDisabled />
      {companyInfo?.externalCustomerRef && (
        <Box margin={{top: 2}}>
          <FormInput label={translate('External customer reference')} name="externalCustomerRef" disabled />
        </Box>
      )}

      <FormSubmitButton margin={{top: 2}}>{translate('Save')}</FormSubmitButton>
    </Form>
  )
}

export default CompanyInformationForm
