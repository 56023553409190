import React, {useEffect} from 'react'
import IProduct from '../../../domain/IProduct'
import useResource from '../../../hooks/useResource'
import {ResourceId} from '../../../types'
import NotFound from '../../NotFound'
import useStore from 'hooks/useStore'

interface IProps extends React.PropsWithChildren {
  id: ResourceId
  component: React.FC<{product: IProduct}>
}

const ProductResolver: React.FC<IProps> = ({id, component: Component, ...props}) => {
  const {productStore} = useStore()
  const {product, isLoading, partyLoaded} = useResource(store => ({
    product: store.productStore.getItem(id),
    isLoading: store.productStore.isLoading,
    partyLoaded: store.profileStore.isLoaded,
  }))

  useEffect(() => {
    productStore.isLoading = true // TODO get rid of this mobx bs
  }, [])

  useEffect(() => {
    if (partyLoaded) {
      productStore.loadManagedProduct(id)
    }
  }, [partyLoaded])

  if (id && !product && !isLoading && partyLoaded) {
    return <NotFound />
  }

  return <Component product={product} {...props} />
}

export default ProductResolver
