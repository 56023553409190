import React, {useState, useMemo} from 'react'
import {min, max} from 'lodash-es'
import Map from 'components/Map'
import {getCountryCoordinates} from 'components/Map/utils'
import useTheme from 'hooks/useTheme'
import PriceCircle from './PriceCircle'
import {mapDataAtom} from 'atoms/marketIntelligence'
import {useAtomValue} from 'jotai'
import {stringifyUnit} from 'helpers/price'
import {formatDecimal} from 'helpers/format'

const getPriceColorIndex = (price: number, minPrice: number, maxPrice: number) => {
  if (minPrice === maxPrice) {
    return 0
  }

  const normalizedPosition = (price - minPrice) / (maxPrice - minPrice)

  return Math.min(Math.floor(normalizedPosition * 8), 7)
}

const LoadedContainer = ({loaded}: {loaded: boolean}) => {
  return <div data-cy={`google-map-${loaded ? 'loaded' : 'loading'}`} style={{display: 'none'}} />
}

interface IProps {}

const MarketMap: React.FC<IProps> = () => {
  const theme = useTheme()
  const [loaded, setLoaded] = useState(false)
  const data = useAtomValue(mapDataAtom)
  const [minPrice, maxPrice] = useMemo(() => {
    return [min(data.map(d => d.price)), max(data.map(d => d.price))]
  }, [data])

  return (
    <Map
      setLoaded={setLoaded}
      styles={[
        {
          featureType: 'administrative.locality',
          elementType: 'labels',
          stylers: [{visibility: 'off'}],
        },
      ]}
      maxZoom={5}
      defaultZoom={3}
    >
      {data.map(({price, country, priceUnit}, index) => {
        const coordinates = getCountryCoordinates(country)

        return (
          <PriceCircle
            price={formatDecimal(price, 2)}
            key={index}
            name={country}
            unit={stringifyUnit(priceUnit)}
            color={theme.colors[`yellow${getPriceColorIndex(price, minPrice, maxPrice)}`]}
            lat={Number(coordinates.latitude)}
            lng={Number(coordinates.longitude)}
          />
        )
      })}
      <LoadedContainer loaded={loaded} />
    </Map>
  )
}

export default MarketMap
