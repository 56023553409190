import React from 'react'
import Table, {TableValue} from 'components/Table'
import {IMarketListing} from 'domain/IMarketListing'
import {DateFormat, formatDate} from 'helpers/date'
import useLocalization from 'hooks/useLocalization'
import {isAdmin} from 'helpers/user'
import useProfile from 'hooks/useProfile'
import Button from 'components/Button'
import Link from 'components/Link'

interface IProps extends React.PropsWithChildren {
  listings: IMarketListing[]
}

const MarketListingsTable: React.FC<IProps> = ({listings}) => {
  const {translate} = useLocalization()
  const {user} = useProfile()

  return (
    <Table<IMarketListing>
      tableName={translate('External Contracts')}
      data={listings}
      linkTo={(listing: IMarketListing) => `/broker/portfolio/marketplace-listings/${listing.id}`}
      columns={[
        {
          title: translate('Product'),
          accessor: (listing: IMarketListing) => listing.productName,
        },
        {
          title: translate('Date'),
          accessor: (listing: IMarketListing) => formatDate(listing.startDate, DateFormat.MONTH_YEAR),
        },
        {
          title: translate('Price per EAC'),
          accessor: (listing: IMarketListing) => listing,
          render: (listing: IMarketListing) => <TableValue value={listing.certPrice} currency={listing.currency} />, // TODO change currency
        },
        {
          title: translate('Total EACs'),
          accessor: (listing: IMarketListing) => listing.totalQuantity,
          render: (total: number) => <TableValue rounded value={total} />,
        },
        {
          title: translate('Sold EACs'),
          accessor: (listing: IMarketListing) => listing.soldQuantity,
          render: (sold: number) => <TableValue rounded value={sold} />,
        },
        {
          title: translate('Reserved EACs'),
          accessor: (listing: IMarketListing) => listing.reservedQuantity,
          render: (reserved: number) => <TableValue rounded value={reserved} />,
        },
        {
          title: translate('Available EACs'),
          accessor: (listing: IMarketListing) => listing.availableQuantity,
          render: (available: number) => <TableValue rounded value={available} />,
        },
        isAdmin(user) && {
          title: null,
          disableLink: true,
          accessor: (listing: IMarketListing) => listing,
          render: (listing: any) => (
            <Link to={`/broker/portfolio/marketplace-listings/${listing.id}/report`}>
              <Button small data-cy="eac-report-button" style={{whiteSpace: 'nowrap'}}>
                {translate('Matched EAC report')}
              </Button>
            </Link>
          ),
        },
      ].filter(Boolean)}
    />
  )
}

export default MarketListingsTable
