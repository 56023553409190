import React, {useEffect, useState} from 'react'
import FullscreenLoader from 'components/FullscreenLoader'
import Page from 'components/Page'
import EditManagedPartyMemberForm, {IFormData} from 'components/Platform/Account/EditManagedPartyMember'
import IManagedPartyMember from 'domain/IManagedPartyMember'
import IPartyMember from 'domain/IPartyMember'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {addDefaultMemberRoles, isPartyAdmin} from 'helpers/partyMember'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import useServices from 'hooks/useServices'
import {Redirect, useParams} from 'react-router-dom'

interface IUrlParamTypes {
  id: string
}

const EditPartyMember: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {contentService} = useServices()
  const {party, partyMembership, isLoaded} = useProfile()
  const {id: partyMemberId} = useParams<IUrlParamTypes>()
  const {addError, addSuccess} = useAlerts()
  const [partyMember, setPartyMember] = useState<IPartyMember>({} as IPartyMember)
  const [isLoading, setIsLoading] = useState(true)

  const handleSubmit = async (formData: IFormData) => {
    const updatedUser = {firstName: formData.firstName, lastName: formData.lastName, email: formData.email}
    // for now we allow only setting one member role from UI, either PARTY_USER or PARTY_ADMIN
    const partyMemberRoles = addDefaultMemberRoles([formData.role])
    const updatePartyMemberReq = {
      id: Number.parseInt(partyMemberId),
      user: updatedUser,
      partyMemberRoles: partyMemberRoles,
    } as IPartyMemberUpdateRequest

    try {
      const partyMember = await contentService.updatePartyMember(party.id, updatePartyMemberReq)
      setPartyMember(partyMember)
      addSuccess(translate('User saved') + '.')
    } catch (ex) {
      console.error(ex)
      addError('Failed to update user.', ex?.correlationId, ex?.message)
    }
  }

  useEffect(() => {
    if (isLoaded) {
      contentService.getPartyMember(party.id, Number.parseInt(partyMemberId)).then(partyMember => {
        setPartyMember(partyMember)
        setIsLoading(false)
      })
    }
  }, [isLoaded])

  if (isLoading || !isLoaded) {
    return <FullscreenLoader />
  }

  if (!isPartyAdmin(partyMembership)) {
    return <Redirect to={'/'} />
  }

  return (
    <Page title={translate('Edit user profile')} description={translate('Edit user')}>
      <EditManagedPartyMemberForm
        partyMember={{...partyMember} as IManagedPartyMember}
        handleSubmit={handleSubmit}
      ></EditManagedPartyMemberForm>
    </Page>
  )
}

export default EditPartyMember
