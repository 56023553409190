import React from 'react'
import ResourceGateway from 'components/ResourceGateway'
import AddPartyMember from './AddPartyMember'
import EditPartyMember from './EditPartyMember'
import {Redirect} from 'react-router'
import ManageUsers from './ManageUsers'
import useProfile from 'hooks/useProfile'
import {isPartyAdmin} from 'helpers/partyMember'
import useLocalization from 'hooks/useLocalization'
import {getPlatformUrl} from 'helpers/party'
import useTheme from 'hooks/useTheme'
import useAlerts from 'hooks/useAlerts'

const CompanyUsers: React.FC<React.PropsWithChildren> = () => {
  const {party, partyMembership, isLoaded} = useProfile()
  const {addError} = useAlerts()
  const theme = useTheme()
  const {translate} = useLocalization()

  const partyRole = party?.partyRole
  const platformUrl = getPlatformUrl(partyRole, theme)

  if (isLoaded && !isPartyAdmin(partyMembership)) {
    addError(translate('Insufficient permissions'))
    return <Redirect to={`${platformUrl}/company`} />
  }

  return (
    <ResourceGateway
      create={() => <AddPartyMember />}
      edit={() => <EditPartyMember />}
      single={() => <Redirect to={`${platformUrl}/company/members`} />}
      list={() => <ManageUsers />}
    />
  )
}

export default CompanyUsers
