import React, {useState} from 'react'
import Table, {TableValue} from 'components/Table'
import {IPerformanceContract} from 'domain/IContract'
import useLocalization from 'hooks/useLocalization'
import {LinkButton} from 'components/Button'
import {CurrencyCode} from 'domain/IPrice'
import TextLink from 'components/TextLink'

interface IProps extends React.PropsWithChildren {
  contracts: IPerformanceContract[]
  currency: CurrencyCode
  dateFilter: {
    startMonth: string
    startYear: number
  }
}

const PerformanceTable: React.FC<IProps> = ({contracts, currency, dateFilter}) => {
  const {translate} = useLocalization()

  return (
    <Table<IPerformanceContract>
      tableName={translate('Contract performance')}
      highlightOnHover
      data={contracts}
      columns={[
        {
          title: translate('Name'),
          accessor: contract => contract,
          render: (contract: IPerformanceContract) => (
            <TextLink to={`/consumer/portfolio/external-contracts/${contract.id}`}>{contract.name}</TextLink>
          ),
        },
        {
          title: translate('PPA price'),
          accessor: (contract: IPerformanceContract) => contract.price,
          render: (price: number) => <TableValue value={price} currency={currency} unit="mwh" />,
        },
        {
          title: translate('Avg. market price'),
          accessor: (contract: IPerformanceContract) => contract.averageMarketPrice,
          render: (averageMarketPrice: number) => (
            <TableValue nowrap value={averageMarketPrice} currency={currency} unit="mwh" />
          ),
        },
        {
          title: translate('Total price'),
          accessor: (contract: IPerformanceContract) => contract.totalPrice,
          render: (totalPrice: number) => <TableValue nowrap value={totalPrice} currency={currency} />,
        },
        {
          title: translate('Total profit'),
          accessor: (contract: IPerformanceContract) => contract.totalProfit,
          render: (totalProfit: number) => <TableValue nowrap value={totalProfit} currency={currency} />,
        },
        {
          title: translate('Profit'),
          accessor: (contract: IPerformanceContract) => contract.averageProfit,
          render: (averageProfit: number) => <TableValue value={averageProfit} currency={currency} unit="mwh" />,
        },
        {
          title: translate('Total volume'),
          accessor: (contract: IPerformanceContract) => contract.volumeMwh,
          render: (volumeMwh: number) => <TableValue value={volumeMwh} unit="mwh" />,
        },
        {
          title: '',
          accessor: (contract: IPerformanceContract) => contract,
          render: (contract: IPerformanceContract) =>
            contract.volumeMwh === 0 && (
              <TextLink
                to={`/consumer/portfolio/external-contracts/${contract.id}?tab=upload&year=${dateFilter.startYear}&month=${dateFilter.startMonth}`}
              >
                {translate('Upload')}
              </TextLink>
            ),
        },
      ]}
    />
  )
}

export default PerformanceTable
