import ProfileStore from './ProfileStore'
import RootService from '../services'
import LocalizationStore from './LocalizationStore'
import ProductStore from './ProductStore'
import UIStore from './UIStore'
import PurchaseContractStore from './PurchaseContractStore'

export default class RootStore {
  public profileStore: ProfileStore
  public localizationStore: LocalizationStore
  public productStore: ProductStore
  public uiStore: UIStore
  public purchaseContractStore: PurchaseContractStore

  public constructor(rootService: RootService) {
    this.localizationStore = new LocalizationStore()
    this.profileStore = new ProfileStore(
      rootService.authenticationService,
      rootService.contentService,
      rootService.v2ContentService,
      this.localizationStore,
    )
    this.uiStore = new UIStore()
    this.productStore = new ProductStore(this, rootService.contentService)
    this.purchaseContractStore = new PurchaseContractStore(this, rootService.contentService)
  }
}
