import React from 'react'
import useStore from 'hooks/useStore'
import useStoreData from 'hooks/useStoreData'
import {getPlatformUrl} from 'helpers/party'
import {useHistory} from 'react-router-dom'
import useTheme from 'hooks/useTheme'

const usePartyActions = () => {
  const partyMemberships = useStoreData(store => store.profileStore.partyMemberships)
  const {profileStore} = useStore()
  const history = useHistory()
  const theme = useTheme()

  const switchParty = (partyId: number, customPath?: string) => {
    const newPartyMember = partyMemberships.find(pm => pm.party.id === partyId)
    profileStore.selectedPartyMemberId = newPartyMember.id
    localStorage.setItem('currentPartyMemberId', `${newPartyMember.id}`)
    history.push(`${getPlatformUrl(newPartyMember?.party?.partyRole, theme)}/${customPath ? customPath : ''}`)
  }

  const refreshParties = async () => {
    await profileStore.load()
  }

  return {
    switchParty,
    refreshParties,
  }
}

export default usePartyActions
