import React, {useEffect} from 'react'
import RawSpotTable from './RawSpotTable'
import {periodAtom, PeriodType} from 'components/PeriodSelect/periodSelect'
import AggrSpotTable from './AggrSpotTable'
import {useAtomValue, useSetAtom} from 'jotai'
import {mapDataAtom} from 'atoms/marketIntelligence'

export const calculatedAvgPricesByCountry = intervals => {
  const total = intervals.length
  const totalNotEmptyByCountry = {}

  return intervals.reduce((acc, interval, index) => {
    interval.prices.forEach(p => {
      if (p.price) {
        totalNotEmptyByCountry[p.country] = (totalNotEmptyByCountry[p.country] || 0) + 1
        acc[p.country] = (acc[p.country] || 0) + +p.price
      }

      if (index === total - 1) {
        acc[p.country] = acc[p.country] / totalNotEmptyByCountry[p.country]
      }
    })
    return acc
  }, {})
}

const SpotPrice: React.FC = () => {
  const period = useAtomValue(periodAtom)
  const setMapData = useSetAtom(mapDataAtom)

  useEffect(() => {
    return () => setMapData([])
  }, [])

  return period.period === PeriodType.QUICK_LINK ? <RawSpotTable /> : <AggrSpotTable />
}

export default SpotPrice
