import React, {useEffect, useMemo} from 'react'
import InnerScroll from 'components/InnerScroll'
import useLocalization from 'hooks/useLocalization'
import ProductDetails from 'components/Platform/Buy/CertificateDesigner/ProductDetails'
import Box from 'components/Box'
import ActionBox from 'components/Platform/ActionBox'
import GeneralFilter from 'components/Platform/Buy/CertificateDesigner/GeneralFilter'
import ProposalDetailsChart from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsChart'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import ModalLink from 'components/Modal/ModalLink'
import {ModalType} from 'components/Modal/IModal'
import Button from 'components/Button'
import Text from 'components/Text'
import RequestModal from 'components/Platform/Buy/CertificateDesigner/RequestModal'
import {useHistory} from 'react-router-dom'
import ProductMap from 'components/Platform/Map/ProductMap'
import {useSetAtom, useAtom, useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {
  filterAtom,
  productAtom,
  productIdAtom,
  proposalDetailsAtom,
  resetDesignerState,
} from 'atoms/certificateDesigner'
import {partyAtom} from 'atoms/party'
import theme from 'theme'
import PageBase from 'components/Platform/Buy/CertificateDesigner/PageBase'
import {buyerProposalsAtom} from 'atoms/proposals'
import {getCurrentYear, getPrevMonth} from 'helpers/date'
import {LinearProgress} from '@mui/material'
import useAlerts from 'hooks/useAlerts'

const CertDesignerProduct: React.FC<{id: string}> = ({id}) => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {addSuccess, addError} = useAlerts()
  const rootPath = '/consumer/buy/certificate-designer/offer'

  const setProductId = useSetAtom(productIdAtom)
  const resetState = useSetAtom(resetDesignerState)
  const {v2ContentService} = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const {data: product, loading: productLoading} = useAtomValue(productAtom)
  const refetchBuyerProposals = useSetAtom(buyerProposalsAtom)
  const {data: proposalDetails, error, loading: detailsLoading} = useAtomValue(proposalDetailsAtom) || {}
  const filter = useAtomValue(filterAtom)
  const currentPartyCountry = party?.location?.addressCountry
  const restrictedToBuy = currentPartyCountry !== product?.site?.location?.addressCountry
  const mapProduct = useMemo(() => [product].filter(Boolean), [product])
  const filterPayload = {
    ...filter,
    product: {productId: product?.id, quantity: filter.quantity || product?.quantity},
    year: getCurrentYear(),
    month: getPrevMonth(),
  }
  const isLoading = productLoading || detailsLoading
  const hasConsumption = Boolean(product?.maxQuantity && product?.maxQuantity > 0)

  useEffect(() => {
    return () => resetState()
  }, [])

  useEffect(() => {
    if (id) {
      setProductId(id)
    }
  }, [id])

  const handleRequest = async () => {
    try {
      const offer = await v2ContentService.saveCertificateOffer(party.id, filterPayload)

      await v2ContentService.sendToSellerReview(party.id, offer.id)

      history.push(`${rootPath}/${offer.id}/product`)
      refetchBuyerProposals()

      addSuccess(translate('Successfully sent request'))
    } catch (e) {
      addError(translate('Failed to send request'), e?.correlationId, e?.message)
    }
  }

  const handleSave = async () => {
    try {
      const offer = await v2ContentService.saveCertificateOffer(party.id, filterPayload)

      if (offer) {
        addSuccess(translate('Successfully saved %s', ''))
        history.push(`${rootPath}/${offer.id}/product`)
      }
    } catch (e) {
      addError(translate('Failed to save %s', ''), e?.correlationId, e?.message)
    }
  }

  return (
    <PageBase
      description={translate('Review certificate mix, key terms and confirm the solution')}
      corner={
        <Box gap={2}>
          {hasConsumption && !restrictedToBuy && (
            <Button onClick={handleSave} variant="secondary">
              {translate('Save')}
            </Button>
          )}
          {hasConsumption && !restrictedToBuy && (
            <ModalLink modal={ModalType.CERTIFICATE_REQUEST}>
              <Button type="button">{translate('Request')}</Button>
            </ModalLink>
          )}
        </Box>
      }
    >
      <RequestModal onSend={handleRequest} />
      <InnerScroll noRightPad>
        <Box direction="column">
          <Box direction="row" gap={2}>
            <Box width="23%" border style={{borderRadius: '8px 8px 0 0', borderBottom: 'none'}}>
              <ActionBox title={translate('General')} fullWidth noBorder>
                <GeneralFilter
                  key={product?.id}
                  editable
                  loading={productLoading}
                  maxQuantity={product?.maxQuantity}
                  error={error}
                />
              </ActionBox>
            </Box>
            <Box
              width="77%"
              border
              style={{
                minHeight: 454,
                borderRadius: '8px 8px 0 0',
                borderBottom: 'none',
              }}
            >
              <ActionBox
                title={
                  <Box direction="row" justify="space-between" gap={2} $maxHeight="28px">
                    <Text size="mlarge" nowrap>
                      {translate('Offer details')}
                    </Text>
                    {!hasConsumption && !error && !isLoading && (
                      <Text size="medium" color={theme.colors.warning} bold>
                        {translate('Upload your consumption')}
                      </Text>
                    )}
                    {hasConsumption && restrictedToBuy && !isLoading && (
                      <Text size="medium" color={theme.colors.warning} bold>
                        {translate(
                          "You can't buy from another country, contact Renewabl to move these certificates to your country",
                        )}
                      </Text>
                    )}
                  </Box>
                }
                fullWidth
                noMargin
                noBorder
              >
                <Box direction="column" gap={1.5}>
                  <ProposalDetailsInfo
                    draft
                    hasConsumption={hasConsumption}
                    proposalDetails={proposalDetails}
                    loading={proposalDetails ? false : detailsLoading}
                  />
                  <Box>
                    <ProposalDetailsChart
                      technology={product?.site.productionTechnology}
                      timeseries={proposalDetails?.timeseries}
                      loading={proposalDetails ? false : detailsLoading}
                    />
                  </Box>
                </Box>
                <Box height={0.5}>{detailsLoading && <LinearProgress />}</Box>
              </ActionBox>
            </Box>
          </Box>

          <Box width="100%" direction="row" style={{minHeight: 295}} gap={2}>
            <Box width="23%" title={translate('Add products')} border style={{borderRadius: '0px 0px 8px 8px'}}>
              <ProductMap products={mapProduct} />
            </Box>

            <Box width="77%" border style={{borderRadius: '0 0 8px 8px'}}>
              <InnerScroll noRightPad>
                <Box>
                  <ProductDetails product={product} quantity={filter.quantity} />
                </Box>
              </InnerScroll>
            </Box>
          </Box>
        </Box>
      </InnerScroll>
    </PageBase>
  )
}

export default CertDesignerProduct
