export enum ProfileUploadKind {
  INVALID_KWH_COLUMN_IN_CSV = 'INVALID_KWH_COLUMN_IN_CSV',
  INVALID_TIMESTAMP_COLUMN_IN_CSV = 'INVALID_TIMESTAMP_COLUMN_IN_CSV',
  INVALID_COLUMN_LEN_IN_CSV = 'INVALID_COLUMN_LEN_IN_CSV',
  TIMESERIES_CSV_PROCESSING_ERROR = 'TIMESERIES_CSV_PROCESSING_ERROR',
  MISSING_EXCEL_HEADER = 'MISSING_EXCEL_HEADER',
  UNSUPPORTED_TIMESERIES_FILE_FORMAT = 'UNSUPPORTED_TIMESERIES_FILE_FORMAT',
  INVALID_TIMESERIES_FILE = 'INVALID_TIMESERIES_FILE',

  INVALID_EXCEL_TIMESTAMP = 'INVALID_EXCEL_TIMESTAMP',
  INVALID_VALUE_KWH_IN_EXCEL = 'INVALID_VALUE_KWH_IN_EXCEL',
  MISSING_QUANTITY_KWH_IN_EXCEL = 'MISSING_QUANTITY_KWH_IN_EXCEL',
}

export enum MeterRefKind {
  UPDATED_METER_REF_ALREADY_IN_USE = 'UPDATED_METER_REF_ALREADY_IN_USE',
  METER_REF_ALREADY_IN_USE = 'METER_REF_ALREADY_IN_USE',
}

export enum BaseUserKind {
  PRODUCT_ALREADY_ON_MARKET = 'PRODUCT_ALREADY_ON_MARKET',
  INVALID_VAT_CODE = 'INVALID_VAT_CODE',
}

export enum UserInviteKind {
  USER_INVITE_BY_ACCESS_CODE_NOT_FOUND = 'USER_INVITE_BY_ACCESS_CODE_NOT_FOUND',
}

export type UserError = ProfileUploadKind | MeterRefKind | BaseUserKind | UserInviteKind | string

interface ProfileUploadErrorPayload {
  attachmentId: number
  fileHead?: string
  row: number
  timestamp?: string
  cause?: string
  actual?: string
  expected?: number
  header?: string
  format?: string
  quantityKwh?: string
  supportedFormats?: string[]
}

export type ErrorKind = {kind: UserError; payload?: any | ProfileUploadErrorPayload}

export default class V2ApiErrorMapper {
  public static getMessage(defaultMessage, error: ErrorKind): string {
    const kind = error?.kind

    return V2ApiErrorMapper.getUserErrorMessage(kind, error?.payload, defaultMessage)
  }

  private static getUserErrorMessage(kind: UserError, payload: ProfileUploadErrorPayload, defaultMsg: string): string {
    switch (kind) {
      case ProfileUploadKind.INVALID_COLUMN_LEN_IN_CSV:
        return `Your csv file contains ${payload.actual} columns, but the number of columns must be ${payload.expected}.`

      case ProfileUploadKind.INVALID_TIMESTAMP_COLUMN_IN_CSV:
        return `Your csv file contains invalid timestamp: ${payload.actual}. Please ensure the timestamp is in the format of "day/month/year hour:minute".`

      case ProfileUploadKind.INVALID_KWH_COLUMN_IN_CSV:
        return `Your csv file contains invalid kwh value: ${payload.actual}. Please ensure the kwh value is a valid number.`

      case ProfileUploadKind.TIMESERIES_CSV_PROCESSING_ERROR:
        return `The csv file is invalid. Please ensure every row has exactly same number of columns for each row.`

      case ProfileUploadKind.MISSING_EXCEL_HEADER:
        return `Your excel file is in the wrong format - please ensure the header ${payload?.header} is present. Please download our example template from the link below.`

      case ProfileUploadKind.UNSUPPORTED_TIMESERIES_FILE_FORMAT:
        return `Your file is in the wrong format ${
          payload?.format
        } please ensure the file is in ${payload?.supportedFormats?.join(', ')} format.`

      case ProfileUploadKind.INVALID_TIMESERIES_FILE:
        return `Invalid timeseries file.`

      case ProfileUploadKind.INVALID_EXCEL_TIMESTAMP:
        return `Invalid timestamp: ${payload?.timestamp} at row ${payload?.row}.`

      case ProfileUploadKind.INVALID_VALUE_KWH_IN_EXCEL:
        return `Invalid Quantity kWh value: ${payload?.quantityKwh} at row ${payload?.row}.`

      case ProfileUploadKind.MISSING_QUANTITY_KWH_IN_EXCEL:
        return `Missing Quantity kWh value at row ${payload?.row}.`

      case MeterRefKind.UPDATED_METER_REF_ALREADY_IN_USE:
      case MeterRefKind.METER_REF_ALREADY_IN_USE:
        return `Your meter reference is already in use. Please enter a different one.`
      case BaseUserKind.PRODUCT_ALREADY_ON_MARKET:
        return 'This product is already listed on the marketplace for the given period.'
      case BaseUserKind.INVALID_VAT_CODE:
        return 'Invalid VAT code.'

      case UserInviteKind.USER_INVITE_BY_ACCESS_CODE_NOT_FOUND:
        return 'Invite link does not exist, already used or has expired. Please contact an administrator.'

      default:
        return defaultMsg
    }
  }
}
