import React from 'react'
import FormInput, {IProps} from './FormInput'
import useLocalization from '../../hooks/useLocalization'

const FormEmail: React.FC<IProps> = props => {
  const {translate} = useLocalization()

  return (
    <FormInput
      label={translate('Email')}
      name="email"
      pattern={{
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: translate('Invalid email address'),
      }}
      {...props}
    />
  )
}

export default FormEmail
