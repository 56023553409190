import React, {useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Form from 'components/Form'
import {useHistory} from 'react-router'
import Page from 'components/Page'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import InnerScroll from 'components/InnerScroll'
import Box from 'components/Box'
import {useAtomValue, useSetAtom} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {DateFormat, formatDate} from 'helpers/date'
import {FormControlLabel, RadioGroup} from '@mui/material'
import Radio from 'components/Radio'
import ProductForm from './ProductForm'
import FormSelect from 'components/Form/FormSelect'
import ProductListingForm from './ProductListingForm'
import Heading from 'components/Heading'
import ResponsiveGrid from 'components/ResponsiveGrid'
import {ScreenBreakpoint} from 'hooks/useScreenSize'
import {basicProductsAtom, marketListingsAtom} from 'atoms/marketListings'
import {IListingDetails, MarketListingKind, QuickProduct} from 'domain/IMarketListing'
import {useLocation} from 'react-router-dom'
import {BaseUserKind, MeterRefKind, ProfileUploadKind} from 'services/V2ApiErrorMapper'
import {TimeseriesKind} from 'domain/ISite'
import useAlerts from 'hooks/useAlerts'

interface IProps extends React.PropsWithChildren {}

const AddMarketplaceListingPage: React.FC<IProps> = () => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const productId = urlSearchParams.get('productId')
  const {translate} = useLocalization()
  const [newProduct, setNewProduct] = useState(false)
  const [uploadType, setUploadType] = useState<TimeseriesKind>(TimeseriesKind.BUILDER)
  const [newSite, setNewSite] = useState(false)
  const {addError, addSuccess} = useAlerts()
  const refetchListings = useSetAtom(marketListingsAtom)
  const party = useAtomValue(partyAtom)
  const {data: products} = useAtomValue(basicProductsAtom)
  const history = useHistory()
  const rootService = useAtomValue(rootServiceAtom)

  const handleSubmit = async (data, {setError}) => {
    try {
      const {interval, listing, site, product} = data
      const {builder, profileAttachment, ...listingRest} = listing || {}

      const listingPayload: IListingDetails = {
        ...listingRest,
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        timeseries: {
          kind: uploadType,
          payload: {
            hourlyVolumes: uploadType === TimeseriesKind.BUILDER ? builder.hourlyVolumes : undefined,
            attachmentId: uploadType === TimeseriesKind.FILE_UPLOAD ? profileAttachment?.id : undefined,
          },
        },
        price: +listing?.price,
      }

      const sitePayload = newSite
        ? {
            kind: MarketListingKind.NEW,
            payload: {
              ...site,
              operationsStart: formatDate(site.operationsStart, DateFormat.YEAR_MONTH_DAY),
              lifetimeYears: +site?.lifetimeYears,
              prodCapacityMwac: +site?.prodCapacityMwac,
              placeId: data?.location?.placeId,
            },
          }
        : {kind: MarketListingKind.EXISTING, payload: {id: +data?.site?.id}}

      const productPayload: QuickProduct = newProduct
        ? {
            kind: MarketListingKind.NEW,
            payload: {
              name: product.name,
              description: product.description,
              imgAttachmentId: product.imgAttachment.id,
              site: sitePayload,
            },
          }
        : {kind: MarketListingKind.EXISTING, payload: {id: +product.id}}

      await rootService.v2ContentService.createMarketListing(party.id, {
        product: productPayload,
        listingDetails: listingPayload,
      })
      refetchListings()
      addSuccess(translate('Successfully added %s', 'marketplace listing'))
      history.push('/broker/portfolio/marketplace-listings')
    } catch (e) {
      const kind = e?.error?.kind
      if (kind in ProfileUploadKind) {
        setError('listing.profileAttachment', 'file', e.message)
        addError(e.message)
      } else if (kind in MeterRefKind) {
        setError('site.meterReference', 'meterReference', e.message)
        addError(e.message)
      } else if (kind in BaseUserKind) {
        addError(e.message)
      } else {
        addError(translate('Failed to save marketplace listing'), e?.correlationId, e?.message)
      }
    }
  }

  const handleRadioChange = (event, isProduct) => {
    const value = event.target.value
    const isTrue = value === 'true'

    if (isProduct) {
      setNewProduct(isTrue)
    } else {
      setNewSite(isTrue)
    }
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight submissionFeedback={null}>
      <Page
        title={translate('Add marketplace listing')}
        description={translate('Manage information about the marketplace listing')}
        noDivider
        corner={
          <Box gap={2}>
            <FormSubmitButton data-cy="save-button">{translate('Save')}</FormSubmitButton>
          </Box>
        }
      >
        <InnerScroll noRightPad>
          <Box pad={{bottom: 2}}>
            <ResponsiveGrid
              gap={2}
              templateColumns={{[ScreenBreakpoint.Large]: '1fr', [ScreenBreakpoint.MLarge]: '2fr 3fr'}}
            >
              <Box pad={2} round border>
                <Heading margin={{bottom: 1}} size="msmall">
                  {translate('1. Select or create product')}
                </Heading>
                <RadioGroup row value={newProduct} onChange={e => handleRadioChange(e, true)}>
                  <FormControlLabel value={false} control={<Radio />} label={translate('Existing product')} />
                  <FormControlLabel
                    data-cy="new-product-radio"
                    value={true}
                    control={<Radio />}
                    label={translate('New product')}
                  />
                </RadioGroup>
                {newProduct ? (
                  <ProductForm newSite={newSite} handleRadioChange={handleRadioChange} />
                ) : (
                  <FormSelect
                    placeholder={translate('Select product')}
                    name="product.id"
                    label={translate('Choose product')}
                    required
                    defaultValue={productId || ''}
                    data-cy="product-select"
                    options={products?.map(product => ({
                      value: product.id,
                      label: product.name,
                    }))}
                  />
                )}
              </Box>

              <ProductListingForm newProduct={newProduct} uploadType={uploadType} setUploadType={setUploadType} />
            </ResponsiveGrid>
          </Box>
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default AddMarketplaceListingPage
