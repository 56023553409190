import React, {useEffect, useState} from 'react'
import useLocalization from '../../../hooks/useLocalization'
import useServices from '../../../hooks/useServices'
import Form from '../../Form'
import Text from '../../Text'
import ICreatedUser from '../../../domain/ICreatedUser'
import useStore from '../../../hooks/useStore'
import TextLink from '../../TextLink'
import Box from 'components/Box'
import {useHistory, useParams} from 'react-router-dom'
import FormInput from 'components/Form/FormInput'
import {StyledFormButton} from './Layout/FormComponents'
import useAlerts from 'hooks/useAlerts'

interface ParamTypes {
  email: string
}

interface IProps extends React.PropsWithChildren {
  createdUser?: ICreatedUser
}

let timeout

const EmailVerificationForm: React.FC<IProps> = ({createdUser}) => {
  const {email} = useParams<ParamTypes>()
  const history = useHistory()
  const {addSuccess} = useAlerts()
  const [showResend, setShowResend] = useState(false)
  const {translate} = useLocalization()
  const {authenticationService} = useServices()
  const {profileStore} = useStore()

  const handleSubmit = async ({code}, {setError}) => {
    try {
      if (email) {
        await authenticationService.confirmSignUp(email, code)
        addSuccess(translate('Your email has been verified, please login again'))
        history.push(`/`)
      } else {
        await authenticationService.confirmSignUp(createdUser.email, code)
        await authenticationService.signIn(createdUser.email, createdUser.password)
        profileStore.setUser(await authenticationService.getAuthenticatedUser())
      }
    } catch (error) {
      setError('code', 'error', error.message)
    }
  }

  useEffect(() => {
    timeout = setTimeout(() => {
      setShowResend(true)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [showResend])

  const handleResend = async () => {
    setShowResend(false)
    await authenticationService.resendSignUp(createdUser?.email || email)
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <FormInput
        large
        placeholder="xxxxxx"
        label={translate('Verification code')}
        name="code"
        required
        data-cy="code"
      />
      <Box justify="space-between" pad={{top: 1}} align="center">
        {showResend || email ? (
          <Text bold>
            {translate('Didn’t receive an email?')}{' '}
            <TextLink onClick={handleResend}>{translate('Click here to resend')}</TextLink>
          </Text>
        ) : (
          <Box />
        )}
        <StyledFormButton variant="primary">{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default EmailVerificationForm
