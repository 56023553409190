import React from 'react'
import useTheme from '../../hooks/useTheme'
import Box from '../Box'
import Text, {ISize} from '../Text'

interface IProps extends React.PropsWithChildren {
  title?: string
  color?: string
  value?: any
  valueParser?: any
  icon?: any
  unit?: string
  unitSize?: ISize
  small?: boolean
  smallValue?: boolean
  semiBoldValue?: boolean
  highlighted?: boolean
  autoHide?: boolean
  currency?: any
}

const KeyFeature: React.FC<IProps> = ({
  title,
  color,
  value,
  valueParser,
  unit,
  unitSize,
  icon,
  small,
  smallValue,
  semiBoldValue = true,
  highlighted,
  autoHide,
  currency,
  children,
}) => {
  const theme = useTheme()

  if (autoHide && !value) {
    return null
  }

  return (
    <Box pad={1} margin={{left: -1}} round color={highlighted ? theme.colors.light3 : null}>
      {title && (
        <Text size="small" uppercase color={color} nowrap>
          {title}
        </Text>
      )}
      <Box align="baseline">
        {icon && <Box margin={{right: 1}}>{icon}</Box>}
        {currency && (
          <Text color={color} size={smallValue || small ? 'medium' : 'xlarge'} semibold={semiBoldValue}>
            {currency}
          </Text>
        )}
        {(value || value === 0) && (
          <Text
            color={color}
            size={smallValue || small ? 'medium' : 'xlarge'}
            semibold={semiBoldValue}
            font={theme.font.numbers}
          >
            {valueParser ? valueParser(value) : value}
          </Text>
        )}
        {unit && (
          <Text
            color={color}
            size={unitSize ? unitSize : small ? 'small' : 'medium'}
            margin={{left: 1}}
            semibold={!small}
          >
            {unit}
          </Text>
        )}
      </Box>
      {children}
    </Box>
  )
}

export default KeyFeature
