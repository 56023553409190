import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import Chip from 'components/PeriodSelect/Chip'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {countriesAtom} from 'atoms/marketIntelligence'
import {useAtom} from 'jotai'
import {Country} from 'domain/ILocation'
import {getAllowedCountries, getHumanReadableCountry} from 'helpers/location'
import useAlerts from 'hooks/useAlerts'
import {Grid} from '@mui/material'

export const CountriesFilter: React.FC<React.PropsWithChildren> = () => {
  const {addError} = useAlerts()
  const {translate} = useLocalization()
  const theme = useTheme()
  const [countries, setCountries] = useAtom(countriesAtom)
  const allowedCountries = getAllowedCountries()

  const handleCountryClick = (country: Country) => {
    if (countries.includes(country)) {
      setCountries(countries.filter(c => c !== country))
    } else {
      if (countries.length < 5) {
        setCountries([...countries, country])
      } else {
        addError(translate('You can select up to %s countries', 5))
      }
    }
  }

  return (
    <Box align="center" gap={1} margin={{vertical: 2}} style={{minHeight: '32px'}}>
      <Box>
        <Text color={theme.colors.accent} size="small" uppercase font={theme.font.secondaryFont} nowrap>
          {translate('Market')}
        </Text>
      </Box>

      <Grid container spacing={1}>
        {allowedCountries.map(country => (
          <Grid item xs="auto" key={country}>
            <Chip
              onClick={() => handleCountryClick(country)}
              label={getHumanReadableCountry(country, translate)}
              selected={countries.includes(country)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
