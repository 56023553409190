import React, {useCallback, useState} from 'react'
import FileDrop, {IProps as IFileDropProps} from '../../FileDrop'
import {FileRejection as IFileRejection} from 'react-dropzone'
import FormInput from '../../Form/FormInput'
import useFormContext from '../../../hooks/useFormContext'
import useLocalization from '../../../hooks/useLocalization'
import useServices from '../../../hooks/useServices'
import Box from '../../Box'
import IAttachment from '../../../domain/IAttachment'
import TextLink from '../../TextLink'
import {partyAtom} from 'atoms/party'
import {useAtomValue} from 'jotai'
import {get} from 'lodash-es'

interface IProps extends React.PropsWithChildren, Omit<IFileDropProps, 'required' | 'value'> {
  name: string
  attachmentName?: string
  required?: boolean | string
  onRemove?: () => void
  value?: any
  removable?: boolean
}

const FormAttachmentUpload: React.FC<IProps> = ({
  name,
  label,
  attachmentName,
  required,
  onRemove,
  removable,
  value: providedValue,
  ...props
}) => {
  const party = useAtomValue(partyAtom)
  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<string>(null)
  const {translate} = useLocalization()
  const {contentService, v2ContentService} = useServices()
  const {setValue, setError, errors, clearError, watch} = useFormContext()

  const value: IAttachment = providedValue || watch(name)
  const error: string = uploadError || get(errors, name)?.message

  const handleUpload = useCallback(
    async (file: File) => {
      setUploading(true)
      setUploadError(null)

      try {
        const attachment = await v2ContentService.uploadAttachment(party?.id, file)

        setValue(name, attachment, true)
        setUploading(false)
      } catch (error) {
        console.error(error)
        setUploadError(translate('Failed to upload %s', label.toLowerCase()))
        setUploading(false)
      }
      // eslint-disable-next-line
    },
    [party],
  )

  return (
    <Box margin={{bottom: 2}}>
      <FileDrop
        {...props}
        label={label}
        loading={uploading}
        onDrop={(acceptedFiles: File[], fileRejections: IFileRejection[]) => {
          if (fileRejections.length) {
            const errorMsg = fileRejections[0]?.errors?.[0]?.message
            setError(name, 'file', translate(errorMsg || `Invalid file ${fileRejections[0].file.name}`))
          } else {
            clearError(name)
            const [file] = acceptedFiles

            if (file) {
              handleUpload(file)
            } else {
              onRemove ? onRemove() : setValue(name, null)
            }
          }
        }}
        error={error}
        currentFile={value?.fileName ? {name: value.fileName} : null}
        removable={removable || !required}
        defaultValue=""
        single
      >
        {value?.id && (
          <TextLink download to={contentService.getAttachmentURL(value)} noColor={!props?.coloredLink}>
            {translate('Download')}
          </TextLink>
        )}
      </FileDrop>
      <FormInput type="hidden" defaultValue={''} required={required} name={name} />
    </Box>
  )
}

export default FormAttachmentUpload
