import Auth from '@aws-amplify/auth'
import Label from 'components/Label'
import Text from 'components/Text'
import useServices from 'hooks/useServices'
import React from 'react'
import {useHistory} from 'react-router'
import useLocalization from 'hooks/useLocalization'
import useStore from 'hooks/useStore'
import Box from 'components/Box'
import Form from 'components/Form'
import {StyledFormButton, StyledFormCheckbox, StyledFormInput} from './Layout/FormComponents'
import useAlerts from 'hooks/useAlerts'
import IUserInvite from 'domain/IUserInvite'
import {useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'

interface IProps extends React.PropsWithChildren {
  user: IUserInvite
  accessCode: string
  v2?: boolean
}

const QuickSignInForm: React.FC<IProps> = ({user, accessCode, v2}) => {
  const {profileStore} = useStore()
  const {addError} = useAlerts()
  const {translate} = useLocalization()
  const history = useHistory()
  const {contentService} = useServices()
  const rootService = useAtomValue(rootServiceAtom)

  const handleSubmit = async ({newPassword}) => {
    try {
      const userData = {password: newPassword}

      if (v2) {
        await rootService.v2ContentService.setPasswordFromInvite(accessCode, userData)
      } else {
        await contentService.setPasswordFromInvite(accessCode, userData)
      }
      await Auth.signIn(user.email, newPassword)
      await profileStore.load()
      history.push('/')
    } catch (err) {
      console.error(err)
      addError(translate('Could not create new password. Please contact an admin.'), err?.correlationId, err?.message)
    }
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <Label text={translate('Email')}></Label>
      <Text semibold size="large">
        {user.email}
      </Text>

      <Box margin={{top: 3}}>
        <StyledFormInput
          margin={{top: 1}}
          label={translate('Create password')}
          name="newPassword"
          type="password"
          placeholder="********************"
          validate={(value: string): string | true => {
            if (value.length < 8) {
              return translate('Must be at least 8 characters.')
            }

            if (!/[a-z]/.test(value)) {
              return translate('Must contain at least one lowercase character.')
            }

            if (!/[0-9]/.test(value)) {
              return translate('Must contain at least one number.')
            }

            if (!/[A-Z]/.test(value)) {
              return translate('Must contain at least one uppercase character.')
            }

            return true
          }}
          required
        />
      </Box>
      <Box margin={{top: 3}}>
        <StyledFormInput
          margin={{top: 1}}
          label={translate('Confirm password')}
          name="confirmPassword"
          type="password"
          placeholder="********************"
          required
          validate={(value, getValues) => value === getValues().newPassword || translate('Passwords do not match')}
        />
      </Box>

      <Box margin={{top: 3}}>
        <StyledFormCheckbox
          name="agreeTerms"
          label={translate('I agree to the privacy policy and terms of the platform')}
          required
        />
        <StyledFormCheckbox
          name="agreeCookies"
          label={translate('I accept the cookie policy of the platform')}
          required
        />
      </Box>

      <Box justify="flex-end" pad={{top: 2}}>
        <StyledFormButton variant="primary">{translate('Sign in')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default QuickSignInForm
