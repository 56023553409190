import React from 'react'
import {alertsAtom} from 'atoms/general'
import {useAtom, useSetAtom} from 'jotai'
import {Alert, AlertType} from 'components/Layout/Alerts'

const DEFAULT_TIMEOUT_MS = 5000

export const useAlerts = () => {
  const setAlerts = useSetAtom(alertsAtom)

  const addAlert = (
    message: string,
    details: string = null,
    type: AlertType,
    timeout = DEFAULT_TIMEOUT_MS,
    correlationId?: string,
  ) => {
    const newAlert = new Alert(message, type, details, correlationId)
    setAlerts(alerts => [...alerts, newAlert])

    setTimeout(() => {
      setAlerts(alerts => alerts.filter(alert => alert.id !== newAlert.id))
    }, timeout)
  }

  const addSuccess = (message: string, details: string = null) => {
    addAlert(message, details, AlertType.Success)
  }

  const addError = (message: string, correlationId?: string, details: string = null) => {
    addAlert(message || 'Something went wrong', details, AlertType.Error, DEFAULT_TIMEOUT_MS, correlationId)
  }

  const addInfo = (message: string, correlationId?: string, details: string = null) => {
    addAlert(message, details, AlertType.Info, 2000)
  }

  return {
    addSuccess,
    addError,
    addInfo,
  }
}

export default useAlerts
