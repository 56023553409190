import React from 'react'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'

interface IProps extends React.PropsWithChildren {
  corner?: any
  description?: any
  loading?: boolean
  descriptionAside?: boolean
}

const PageBase: React.FC<IProps> = ({description, corner, loading, children, descriptionAside}) => {
  const {translate} = useLocalization()

  return (
    <Page
      title={translate('Certificate procurement designer')}
      description={description || translate('Review certificate mix, key terms and confirm the solution')}
      descriptionAside={descriptionAside}
      corner={corner}
      isLoading={loading}
      noDivider
    >
      {children}
    </Page>
  )
}

export default PageBase
