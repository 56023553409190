import {atomWithCompare} from 'atoms/utils'
import {generateYears, getCurrentMonth, getCurrentYear, getMonths, getPrevMonth} from 'helpers/date'
import {atom} from 'jotai'
import {selectAtom} from 'jotai/utils'

export enum PeriodType {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
  QUICK_LINK = 'QUICK_LINK',
}

export enum PeriodValue {
  WHOLE_YEAR = 'Whole Year',
  CUSTOM_PERIOD = 'Custom Period',
}

export enum QuickLinkValue {
  YESTERDAY = 'YESTERDAY',
  CUSTOM_DAY = 'Custom Day',
}

export interface IValue {
  startMonth: string
  startYear: number
  endMonth: string
  endYear: number
}

export interface IPeriod {
  period: PeriodType
  value: string
}

export const MONTHS = getMonths()
export const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']
export const YEARS = generateYears(getCurrentYear(), 5).reverse()

export const periodAtom = atom<IPeriod>({
  period: PeriodType.MONTH,
  value: MONTHS[getCurrentMonth() - 1],
})

export const periodTypeAtom = selectAtom(periodAtom, period => period.period)

const defaultPeriod = {
  startMonth: getPrevMonth(),
  startYear: getCurrentYear(),
  endMonth: getPrevMonth(),
  endYear: getCurrentYear(),
}

export const customPeriodAtom = atomWithCompare(defaultPeriod)

export const yearAtom = atom(getCurrentYear())

export const resetPeriodAtom = atom(null, (get, set) => {
  set(periodAtom, {period: PeriodType.MONTH, value: MONTHS[getCurrentMonth() - 1]})
})
