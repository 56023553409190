import ITranslate from '../interfaces/ITranslate'
import {Country} from '../domain/ILocation'

export function getHumanReadableCountries(translate: ITranslate): {[key in Country]: string} {
  return {
    [Country.ALB]: translate('Albania'),
    [Country.AUT]: translate('Austria'),
    [Country.BEL]: translate('Belgium'),
    [Country.BIH]: translate('Bosnia and Herzegovina'),
    [Country.BGR]: translate('Bulgaria'),
    [Country.CHE]: translate('Switzerland'),
    [Country.CYP]: translate('Cyprus'),
    [Country.CZE]: translate('Czech Republic'),
    [Country.HRV]: translate('Croatia'),
    [Country.DEU]: translate('Germany'),
    [Country.DNK]: translate('Denmark'),
    [Country.ESP]: translate('Spain'),
    [Country.EST]: translate('Estonia'),
    [Country.FIN]: translate('Finland'),
    [Country.FRA]: translate('France'),
    [Country.GEO]: translate('Georgia'),
    [Country.GRC]: translate('Greece'),
    [Country.HUN]: translate('Hungary'),
    [Country.IRL]: translate('Ireland'),
    [Country.ISL]: translate('Iceland'),
    [Country.ITA]: translate('Italy'),
    [Country.LTU]: translate('Lithuania'),
    [Country.LVA]: translate('Latvia'),
    [Country.LUX]: translate('Luxembourg'),
    [Country.MNE]: translate('Montenegro'),
    [Country.NLD]: translate('Netherlands'),
    [Country.NOR]: translate('Norway'),
    [Country.PRT]: translate('Portugal'),
    [Country.ROU]: translate('Romania'),
    [Country.SRB]: translate('Serbia'),
    [Country.SVN]: translate('Slovenia'),
    [Country.SWE]: translate('Sweden'),
    [Country.UKR]: translate('Ukraine'),
    [Country.GBR]: translate('United Kingdom'),
  }
}

export const getAllowedCountries = () => [
  Country.GBR,
  Country.ESP,
  Country.DEU,
  Country.FRA,
  Country.BGR,
  Country.PRT,
  Country.ROU,
  Country.CZE,
  Country.EST,
  Country.AUT,
  Country.IRL,
]

// The list of countries and their vat regex is taken from https://stackoverflow.com/questions/33625770/check-vat-number-for-syntactical-correctness-with-regex-possible/33627030#33627030
export const countryVatRegex = new RegExp(
  '^(' +
    '(AT)?U[0-9]{8}|' + // Austria
    '(BE)?[0-1][0-9]{9}|' + // Belgium
    '(BG)?[0-9]{9,10}|' + // Bulgaria
    '(HR)?[0-9]{11}|' + // Croatia
    '(CY)?[0-9]{8}[A-Z]|' + // Cyprus
    '(CZ)?[0-9]{8,10}|' + // Czech Republic
    '(DE)?[0-9]{9}|' + // Germany
    '(DK)?[0-9]{8}|' + // Denmark
    '(EE)?[0-9]{9}|' + // Estonia
    '(EL)?[0-9]{9}|' + // Greece
    '(ES)?[A-Z][0-9]{7}(?:[0-9]|[A-Z])|' + // Spain
    '(FI)?[0-9]{8}|' + // Finland
    '(FR)?[0-9A-Z]{2}[0-9]{9}|' + // France
    '(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|' + // United Kingdom
    '(HU)?[0-9]{8}|' + // Hungary
    '(IE)?([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|' + // Ireland
    '(IT)?[0-9]{11}|' + // Italy
    '(LT)?([0-9]{9}|[0-9]{12})|' + // Lithuania
    '(LU)?[0-9]{8}|' + // Luxembourg
    '(LV)?[0-9]{11}|' + // Latvia
    '(MT)?[0-9]{8}|' + // Malta
    '(NL)?[0-9]{9}B[0-9]{2}|' + // Netherlands
    '(PL)?[0-9]{10}|' + // Poland
    '(PT)?[0-9]{9}|' + // Portugal
    '(RO)?[0-9]{2,10}|' + // Romania
    '(SE)?[0-9]{12}|' + // Sweden
    '(SI)?[0-9]{8}|' + // Slovenia
    '(SK)?[0-9]{10}' + // Slovakia
    ')$',
  'i',
)

export function getHumanReadableCountry(country: Country, translate: ITranslate) {
  const countries = getHumanReadableCountries(translate)

  return countries[country] || country
}

export function getProductCountry(product, translate: ITranslate) {
  const country = product?.site?.location?.addressCountry
  if (country) {
    return getHumanReadableCountry(country, translate)
  }

  return getHumanReadableCountry(Country.GBR, translate)
}
