import React, {useState, useRef} from 'react'
import {greenCertificatesAtom, overviewDateFiltermAtom} from 'atoms/portfolioOverview'
import Box from 'components/Box'
import Filter, {IChoice} from 'components/Filter'
import Input from 'components/Input'
import Page from 'components/Page'
import {capitalize} from 'lodash-es'
import CertificatesChart from 'components/Platform/Portfolio/GreenCertificate/CertificatesChart'
import Select from 'components/Select'
import Text from 'components/Text'
import {SiteProductionTechnology} from 'domain/ISite'
import {getChangeEventValue} from 'helpers/misc'
import useLocalization from 'hooks/useLocalization'
import {useAtom, useAtomValue} from 'jotai'
import InnerScroll from 'components/InnerScroll'
import Button from 'components/Button'
import useTheme from 'hooks/useTheme'
import {getHumanReadableCountry} from 'helpers/location'
import MultiSelect from 'components/MultiSelect'
import {StyledHeadCell, StyledRow, StyledTable} from 'components/Table'
import Form from 'components/Form'
import FormSelect from 'components/Form/FormSelect'
import FormInput from 'components/Form/FormInput'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {FormControlLabel, IconButton, RadioGroup} from '@mui/material'
import {uniqueId} from 'lodash-es'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import FullscreenLoader from 'components/FullscreenLoader'
import {DateFormat, formatDate, getMonths, getPastYears} from 'helpers/date'
import Radio from 'components/Radio'
import {VolumePrefKind} from 'domain/ITenderRequest'
import useAlerts from 'hooks/useAlerts'

const generateRange = (start, end) => Array.from({length: end - start}, (v, k) => k + start)
const generateEmptyOption = label => ({value: '', label, disabled: true})
const validateFields = fields => {
  let valid = true
  Object.keys(fields).forEach(key => {
    const value = fields[key]
    const isArray = Array.isArray(value)
    if ((isArray && value.length === 0) || (!isArray && !value)) {
      valid = false
    }
  })

  return valid
}

const validateVolumePreferences = item => {
  if (item.kind === VolumePrefKind.CUSTOM) {
    return item.payload?.monthlyCertificates?.length > 0
  }

  return item.payload?.annualVolumeMwh > 0
}

const ALL_TECHNOLOGIES_SLUG = 'ALL'

const TenderRequest: React.FC<React.PropsWithChildren> = () => {
  const [volumePrefType, setVolumePrefType] = useState<VolumePrefKind>(VolumePrefKind.PRODUCED)
  const {translate} = useLocalization()
  const {addError, addSuccess} = useAlerts()
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const lastItemRef = useRef<HTMLTableRowElement>(null)
  const theme = useTheme()
  const months = getMonths()
  const [volumes, setVolumes] = useState([])
  const totalVolume = volumes.reduce((acc, volume) => acc + volume.quantity, 0)
  const years = getPastYears(4)
  const {data: certData, loading} = useAtomValue(greenCertificatesAtom)
  const [dateFilter, setDateFilter] = useAtom(overviewDateFiltermAtom)
  const technologyOptions = [
    SiteProductionTechnology.HYDRO,
    SiteProductionTechnology.WIND,
    SiteProductionTechnology.SOLAR,
  ]
  const allTechnologies: IChoice = {
    name: translate('All'),
    slug: ALL_TECHNOLOGIES_SLUG,
  }
  const [tenderData, setTenderData] = useState({
    technologies: [allTechnologies],
    countries: [],
    maxAgeYears: undefined,
    deliveryStartsOn: '',
    deliveryEndsOn: '',
    eacDemand: '',
  })

  const technologies: IChoice[] = [
    allTechnologies,
    ...technologyOptions.map(technology => ({
      name: translate(capitalize(SiteProductionTechnology[technology])).toUpperCase(),
      slug: technology,
    })),
  ]

  const handleDateChange = (event, key) => {
    const value = getChangeEventValue(event)

    setDateFilter({...dateFilter, [key]: value})
  }

  const handleTenderDataChange = (e, key, isNumber = true) => {
    const value = getChangeEventValue(e)

    setTenderData({...tenderData, [key]: isNumber ? +value : value})
  }

  const handleSubmit = values => {
    setVolumes([...volumes, {id: uniqueId(), quantity: +values.quantity, month: +values.month, year: +values.year}])
    lastItemRef.current.scrollIntoView({behavior: 'smooth'})
  }

  const handleSend = async () => {
    const {countries, technologies, eacDemand, deliveryStartsOn, deliveryEndsOn, ...rest} = tenderData
    const formattedTechnologies = technologies.map(tech => tech.slug)
    const volumePreferences = {
      kind: volumePrefType,
      payload: {
        monthlyCertificates:
          volumePrefType === VolumePrefKind.CUSTOM
            ? volumes.map(({id, ...rest}) => ({
                ...rest,
              }))
            : undefined,
        annualVolumeMwh: volumePrefType === VolumePrefKind.PRODUCED ? +eacDemand : undefined,
      },
    }
    const dataToValidate = {
      ...rest,
      deliveryStartsOn: formatDate(deliveryStartsOn, DateFormat.YEAR_MONTH_DAY),
      deliveryEndsOn: formatDate(deliveryEndsOn, DateFormat.YEAR_MONTH_DAY),
      technologies: formattedTechnologies.includes(ALL_TECHNOLOGIES_SLUG)
        ? technologyOptions
        : (formattedTechnologies as SiteProductionTechnology[]),
    }

    if (!validateFields(dataToValidate) || !validateVolumePreferences(volumePreferences)) {
      addError(translate('All fields must be filled'))
      return
    }

    try {
      await rootService.v2ContentService.sendTenderRequest(party.id, {
        ...dataToValidate,
        volumePreferences,
        countries: countries?.map(country => country.value),
      })
      addSuccess(translate('Successfully sent tender request'))
    } catch (e) {
      addError(translate('Failed to send tender request'), e?.correlationId, e?.message)
    }
  }

  if (loading) return <FullscreenLoader />

  return (
    <Page
      title={translate('Renewabl Tender')}
      description={translate('Send a tender request')}
      corner={
        <Box direction="row" align="center" gap={2}>
          <Box>
            <Button onClick={handleSend}>{translate('Send')}</Button>
          </Box>
        </Box>
      }
    >
      <InnerScroll noRightPad>
        <Box direction="row" gap={2} margin={{top: 0}}>
          <Select
            value={dateFilter.month}
            label={translate('Month')}
            options={months.map(month => ({value: month, label: month}))}
            onChange={e => handleDateChange(e, 'month')}
          />
          <Select
            value={dateFilter.year}
            label={translate('Year')}
            options={years.map(year => ({value: year, label: `${year}`}))}
            onChange={e => handleDateChange(e, 'year')}
          />
        </Box>
        <CertificatesChart certData={certData} filter={{showExcess: true, showMisisng: true}} />
        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box>
            <Text size="xlarge" bold>
              {translate('Technology preferences')}
            </Text>
            <Filter
              choices={technologies}
              multiselect
              colored
              transparent
              skipDate
              technology
              selected={tenderData.technologies}
              name="technologies"
              setActiveChoice={choices => {
                if (Array.isArray(choices) && choices.length === 0) {
                  return
                }
                setTenderData({...tenderData, technologies: choices as IChoice[]})
              }}
            />
          </Box>

          <Box width="408px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Location preferences')}
            </Text>
            <MultiSelect
              value={tenderData.countries as any}
              placeholder={translate('Any AIB country')}
              data={[
                ...theme.countries.map(country => ({
                  value: country,
                  label: getHumanReadableCountry(country, translate),
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'countries', false)}
              parse={value => value}
            />
          </Box>
        </Box>

        <Box margin={{top: 3}} direction="row" gap={5}>
          <Box width="400px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Generation plant max age')}
            </Text>
            <Select
              value={tenderData.maxAgeYears}
              defaultValue={''}
              type="number"
              options={[
                generateEmptyOption(translate('Select max age')),
                ...generateRange(1, 16).map(year => ({
                  value: year,
                  label: `${year} ${translate(year === 1 ? 'year' : 'years')}`,
                })),
              ]}
              onChange={e => handleTenderDataChange(e, 'maxAgeYears')}
            />
          </Box>
          <Box width="410px">
            <Text size="xlarge" bold margin={{bottom: 2}}>
              {translate('Delivery period')}
            </Text>
            <Box direction="row" gap={2} style={{marginTop: '-21px'}} width="100%">
              <Box width="50%">
                <Input
                  type="date"
                  label={translate('start')}
                  margin={0}
                  value={tenderData.deliveryStartsOn}
                  onChange={e => handleTenderDataChange(e, 'deliveryStartsOn', false)}
                />
              </Box>
              <Box width="50%">
                <Input
                  type="date"
                  label={translate('end')}
                  margin={0}
                  value={tenderData.deliveryEndsOn}
                  onChange={e => handleTenderDataChange(e, 'deliveryEndsOn', false)}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box margin={{top: 3}}>
          <Box direction="row" justify="space-between" pad={{right: 2}}>
            <Text size="xlarge" bold>
              {translate('Volume preferences')}
            </Text>
            {volumePrefType === VolumePrefKind.CUSTOM && (
              <Text size="large" bold margin={{top: 2}}>
                {translate('Total certificate quantity')}: {totalVolume}
              </Text>
            )}
          </Box>
          <RadioGroup
            sx={{m: 2, ml: 1}}
            row
            value={volumePrefType}
            onChange={e => setVolumePrefType(getChangeEventValue(e))}
          >
            <FormControlLabel
              value={VolumePrefKind.PRODUCED}
              control={<Radio />}
              label={translate('Pay as produced')}
            />
            <FormControlLabel value={VolumePrefKind.CUSTOM} control={<Radio />} label={translate('Custom')} />
          </RadioGroup>

          {volumePrefType === VolumePrefKind.PRODUCED && (
            <Box width="400px">
              <Input
                label={translate('Annual demand')}
                value={tenderData.eacDemand}
                suffix={translate('MWh ')}
                type="number"
                placeholder="10000"
                onChange={e => handleTenderDataChange(e, 'eacDemand', false)}
              />
            </Box>
          )}
          {volumePrefType === VolumePrefKind.CUSTOM && (
            <Form onSubmit={handleSubmit} submissionFeedback={false} resetOnSubmit defaultValues={{quantity: null}}>
              <StyledTable>
                <thead>
                  <tr>
                    <StyledHeadCell>{translate('Year')}</StyledHeadCell>
                    <StyledHeadCell>{translate('Month')}</StyledHeadCell>
                    <StyledHeadCell>{translate('Certificate quantity')}</StyledHeadCell>
                    <StyledHeadCell>{translate('Actions')}</StyledHeadCell>
                  </tr>
                </thead>
                <tbody>
                  <StyledRow>
                    <td>
                      <FormSelect
                        required
                        name="year"
                        margin={0}
                        options={years.map(year => ({value: year, label: `${year}`}))}
                        placeholder="Year"
                      />
                    </td>
                    <td>
                      <FormSelect
                        required
                        name="month"
                        margin={0}
                        options={months.map((month, index) => ({value: index + 1, label: month}))}
                        placeholder="Month"
                      />
                    </td>
                    <td>
                      <FormInput
                        type="number"
                        defaultValue={null}
                        required
                        name="quantity"
                        margin={0}
                        placeholder="Certificates"
                      />
                    </td>
                    <td width="10%">
                      <IconButton type="submit">
                        <AddIcon />
                      </IconButton>
                    </td>
                  </StyledRow>
                  {volumes.map(volume => (
                    <StyledRow key={volume.id}>
                      <td>{volume.year}</td>
                      <td>{volume.month}</td>
                      <td>{volume.quantity}</td>
                      <td>
                        <IconButton
                          color="warning"
                          onClick={() => setVolumes(volumes.filter(item => item.id !== volume.id))}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </StyledRow>
                  ))}
                  <tr ref={lastItemRef} />
                </tbody>
              </StyledTable>
            </Form>
          )}
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default TenderRequest
