import React, {useEffect, useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import FormInput from 'components/Form/FormInput'
import FormRange from 'components/Form/FormRange'
import Label from 'components/Label'
import ValueWithUnit from 'components/ValueWithUnit'
import IExternalContract from 'domain/IExternalContract'
import {DateFormat, formatDate} from 'helpers/date'

interface IProps extends React.PropsWithChildren {
  contract?: IExternalContract
}

const ExternalContractViewForm: React.FC<IProps> = ({contract}) => {
  const {translate} = useLocalization()
  const [volume, setVolume] = useState(100)
  const disabled = !!contract

  useEffect(() => {
    if (contract) setVolume(+contract.volumePercent)
  }, [contract])

  return (
    <>
      <FormInput
        name="name"
        disabled={disabled}
        label={translate('Name')}
        required
        defaultValue={contract?.name || ''}
      />
      <Box margin={{vertical: 1}} gap={2} direction="column">
        <FormRange
          label={translate('Change volume')}
          name="volumePercent"
          min={1}
          max={100}
          step={1}
          defaultValue={contract?.volumePercent || 100}
          disabled={disabled}
          onChange={value => setVolume(+value)}
        />
        <Label text={'Volume'}>
          <ValueWithUnit size="mlarge" value={volume} unit={'%'} />
        </Label>
      </Box>
      <FormInput
        disabled={disabled}
        name="pricePerMwh"
        label={translate('Price per mwh')}
        required
        defaultValue={contract?.pricePerMwh || ''}
      />
      <FormInput
        disabled={disabled}
        type="date"
        name="startDate"
        label={translate('Start date')}
        required
        defaultValue={contract?.startDate ? formatDate(new Date(contract?.startDate), DateFormat.YEAR_MONTH_DAY) : ''}
      />
      <FormInput
        disabled={disabled}
        type="date"
        name="endDate"
        label={translate('End date')}
        required
        defaultValue={contract?.endDate ? formatDate(new Date(contract?.endDate), DateFormat.YEAR_MONTH_DAY) : ''}
      />
      <FormInput
        disabled={disabled}
        name="externalRef"
        label={translate('External reference')}
        defaultValue={contract.externalRef || ''}
      />
    </>
  )
}

export default ExternalContractViewForm
