import React from 'react'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import VerticalStack from 'components/VerticalStack'
import {SubHeading, Heading} from '../Heading'
import {sellerOnboardingAtom} from 'atoms/onboarding'
import {useAtomValue} from 'jotai'
import {OnboardingStatus, progress} from 'domain/IParty'
import NavItem from '../Layout/NavItem'

enum SellerOnboardingStep {
  SETUP_SITES = 'SETUP_SITES',
  CREATE_PRODUCTS = 'CREATE_PRODUCTS',
  REVIEW_AND_PUBLISH = 'REVIEW_AND_PUBLISH',
}

interface IProps extends React.PropsWithChildren {
  step?: SellerOnboardingStep
}

const SellerOnboardingAside: React.FC<IProps> = step => {
  const theme = useTheme()
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(sellerOnboardingAtom)
  const currProgress = onboardResult && progress(onboardResult.onboardingStatus)
  const createSiteCompleted = currProgress >= progress(OnboardingStatus.SELLER_SITE_CREATED)
  const createProductCompleted = currProgress >= progress(OnboardingStatus.SELLER_PRODUCT_CREATED)
  const onboardCompleted = currProgress === progress(OnboardingStatus.COMPLETED)
  return (
    <Box color={theme.colors.surface}>
      <Box pad={2} color={theme.colors.primary} hoverColor={theme.colors.primaryDark} height={8}>
        <VerticalStack>
          <Heading>Get going in 3 easy steps</Heading>
          <SubHeading black>In few minutes you are good to go!</SubHeading>
        </VerticalStack>
      </Box>
      <NavItem to="/broker/onboarding/setup-site" disabled $completed={createSiteCompleted}>
        <VerticalStack>
          <Heading>1. Set up site</Heading>
          <SubHeading>That powers your product</SubHeading>
        </VerticalStack>
      </NavItem>
      <NavItem to="/broker/onboarding/create-product" disabled $completed={createProductCompleted}>
        <Box>
          <Heading>2. Create product</Heading>
          <SubHeading>To offer green certificates</SubHeading>
        </Box>
      </NavItem>
      <NavItem to={'/broker/onboarding/review'} disabled $completed={onboardCompleted} $withBottomBorder>
        <Box>
          <Heading>3. Review and publish</Heading>
          <SubHeading>Let the buyers know about your product</SubHeading>
        </Box>
      </NavItem>
      <Box style={{padding: 16}}>
        <Box>
          <Heading>Start now</Heading>
          <SubHeading>Its that easy!</SubHeading>
        </Box>
      </Box>
    </Box>
  )
}

export default SellerOnboardingAside
