import IAuthenticationService from './IAuthenticationService'
import CognitoAuthenticationService from './CognitoAuthenticationService'
import LocalAuthenticationService from './LocalAuthenticationService'
import ApiClient from './ApiClient'
import IContentService from './IContentService'
import LocalContentService from './LocalContentService'
import LederhosenContentService from './LederhosenContentService'
import IHttpClient from './IHttpClient'
import LederhosenApiErrorMapper from './LederhosenApiErrorMapper'
import IV2ContentService from './IV2ContentService'
import V2CoreContentService from './V2CoreContentService'
import MockV2ContentService from './MockV2Service'
import V2ApiError from 'exceptions/V2ApiError'
import V2ApiErrorMapper from './V2ApiErrorMapper'
import env from '@beam-australia/react-env'

export default class RootService {
  private static instance: RootService
  public readonly authenticationService: IAuthenticationService
  public readonly contentService: IContentService
  public readonly v2ContentService: IV2ContentService

  constructor() {
    this.authenticationService = this.getAuthenticationService()
    this.v2ContentService = this.getV2ContentService()
    this.contentService = this.getContentService()

    if (env('SERVICES_EXPOSED') === 'true') {
      import('./ServiceExposer').then(({default: ServiceExposer}) => ServiceExposer.create(this))
    }
  }

  public static create(): RootService {
    if (RootService.instance) {
      return RootService.instance
    }

    const instance: RootService = new RootService()
    RootService.instance = instance

    return instance
  }

  private getAuthenticationService(): IAuthenticationService {
    switch (env('SERVICES_AUTHENTICATION')) {
      case 'cognito':
        return new CognitoAuthenticationService(
          {
            region: env('COGNITO_REGION'),
            userPoolId: env('COGNITO_USER_POOL_ID'),
            userPoolWebClientId: env('COGNITO_USER_POOL_WEB_CLIENT_ID'),
          },
          `${env('CORE_V2_URL')}/v2/api/auth`,
        )
      case 'local':
        return new LocalAuthenticationService()
      default:
        throw new Error(`Invalid configuration for services.authentication`)
    }
  }

  private getV2ContentService(): IV2ContentService {
    switch (env('SERVICES_V2_CONTENT')) {
      case 'coreV2':
        return new V2CoreContentService(this.getV2ApiClient())
      case 'local':
        return new MockV2ContentService()
      default:
        throw new Error(`Invalid configuration for services.v2Content: ${JSON.stringify(env('SERVICES_V2_CONTENT'))}`)
    }
  }

  private getContentService(): IContentService {
    switch (env('SERVICES_CONTENT')) {
      case 'lederhosen':
        return new LederhosenContentService(this.getLederhosenApiClient())
      case 'local':
        return new LocalContentService()
      default:
        throw new Error(`Invalid configuration for services.content`)
    }
  }

  private getLederhosenApiClient(): IHttpClient {
    if (!env('LEDERHOSEN_URL')) {
      throw new Error(`Invalid configuration for lederhosen`)
    }
    return ApiClient.create(this.authenticationService, `${env('LEDERHOSEN_URL')}/api`, {
      errorMappingFn: LederhosenApiErrorMapper.getMessageByErrorType,
    })
  }

  private getV2ApiClient(): IHttpClient {
    if (!env('CORE_V2_URL')) {
      throw new Error(`Invalid configuration for Core V2`)
    }
    // both lederhosen(v1) and v2 are under a reverse proxy(for non-local envs)
    // The load balancer forwards urls that start with `/v2/api/` to v2
    return ApiClient.create(this.authenticationService, `${env('CORE_V2_URL')}/v2/api`, {
      //TODO: remove this and add proper error mapping
      errorMappingFn: response => {
        if (!response) {
          return new V2ApiError('Failed to load data. Please check your network connection.', {
            kind: 'CONNECTION_ERROR',
            payload: null,
          })
        }

        if (response.status === 401) {
          this.authenticationService.signOut()
          window.location.reload()
        }

        const data = response.data
        // UUID v4
        const correlationId = response.headers && response.headers['X-Correlation-Id'.toLowerCase()]
        const parsedMsg = V2ApiErrorMapper.getMessage(data?.message, data?.error)
        const error = data?.error
        return new V2ApiError(parsedMsg, error, correlationId)
      },
    })
  }
}
