import {atom, PrimitiveAtom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const buyerContractsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    return await services.v2ContentService.getBuyerContracts(party.id, page, pageSize)
  }),
)

export const offerIdAtom = atom<number>(null) as PrimitiveAtom<number>

export const buyerContractAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const offerId = get(offerIdAtom)

    if (!offerId) {
      return null
    }

    return await services.v2ContentService.getSnapshottedCertificatePurchaseOffer(party.id, offerId)
  }),
  {nullIsLoading: true},
)

export const sellerContractAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const offerId = get(offerIdAtom)

    if (offerId === null) return null

    const offer = await services.v2ContentService.getCertificatePurchaseOffer(party.id, +offerId)

    return offer
  }),
  {nullIsLoading: true},
)
