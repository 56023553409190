import React from 'react'
import styled from 'styled-components'

const StyledCircle = styled.div<{color: string; hovered?: boolean}>`
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  outline: none;
  border: 2px solid ${props => props.color};
  transform: translate(-50%, -130%);
  cursor: pointer;
  transform: ${props => props?.hovered && 'translate(-50%, -150%) scale(1.3)'};

  &:hover {
    transform: translate(-50%, -150%) scale(1.3);
    backface-visibility: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    border-top: 17px solid ${props => props.color};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 110%;
    left: 40%;
    margin-left: -5px;
    margin-top: -5px;
  }
`

interface ICircleMarkerProps {
  children: React.ReactNode
  hasMouseOver?: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
  color: string
}

const CircleMarker: React.FC<ICircleMarkerProps> = ({children, color, onMouseOut, onMouseOver, hasMouseOver}) => {
  return (
    <StyledCircle color={color} onMouseOver={onMouseOver} onMouseOut={onMouseOut} hovered={hasMouseOver}>
      {children}
    </StyledCircle>
  )
}

export default CircleMarker
