import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import IPurchaseContract from 'domain/IPurchaseContract'
import {
  IRecommendedExcessPrice,
  default as ISellExcessRequest,
  default as SellExcessRequest,
} from 'domain/ISellExcessRequest'
import ITextDocument from 'domain/ITextDocument'
import IUser from 'domain/IUser'
import {ITimeseriesOverview} from 'domain/Portfolio'
import {EnergyDataType} from '../domain/EnergyDataType'
import IAttachment from '../domain/IAttachment'
import {SellExcessPayload} from '../domain/INewTimeseriesItem'
import IOffer, {IOfferInvite} from '../domain/IOffer'
import IParty from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import {IPasswordFromOfferInvite} from '../domain/IPasswordFromOfferInvite'
import IProduct from '../domain/IProduct'
import IProductType from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITimeseriesItem from '../domain/ITimeseriesItem'

export enum Interval {
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export default interface IContentService {
  getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument>

  sendContractVerificationCode(contractId: number)

  confirmContractVerificationCode(contractId: number, code: string)

  confirmUserPhoneNumber(code: string): Promise<void>

  sendUserPhoneVerification(phone: string): Promise<void>

  createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember>

  getProductManagedByParty(partyId: any, id: any): Promise<IProduct>

  editCertificateOffer(partyId: any, offerId: any, payload: IProposalDetailsBody): Promise<IOffer>

  getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob>

  getContractContractTemplates(contractId: any, partyId: any): Promise<any>

  getContractHtml(contractId: any): Promise<string>

  getPartyMembers(asPartyId: any): Promise<IPartyMember[]>

  getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void>

  setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void>

  getUserFromInvite(accessCode: string): Promise<IUser>

  createPartyMemberUser(asPartyId: number, partyMember: IAdminCreatePartyMember): Promise<IPartyMember>

  updatePartyMember(asPartyId: any, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember>

  disablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  enablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  getAttachmentURL(attachment: IAttachment): string

  getSalesContracts(partyId: any): Promise<IPurchaseContract[]>
}
