import React, {useEffect, useState} from 'react'
import Button from 'components/Button'
import AccountPage from 'components/Platform/Account/AccountPage'
import ManagePartyMembersTable from '../../../components/Platform/Account/ManagePartyMembersTable'
import IManagedPartyMember from '../../../domain/IManagedPartyMember'
import {partyMemberToManaged} from 'domain/IManagedUser'
import {PartyRole} from 'domain/IParty'
import {getPlatformUrl} from 'helpers/party'
import {toManagedUser} from 'helpers/user'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import useServices from 'hooks/useServices'
import {Link, Redirect} from 'react-router-dom'
import theme from 'theme'
import useAlerts from 'hooks/useAlerts'

const validRolesUrlPrefixes = {
  [PartyRole.BUYER]: '/consumer',
  [PartyRole.PRODUCER]: '/producer',
  [PartyRole.BROKER]: '/broker',
}

const ManageUsers: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {contentService} = useServices()
  const {addError, addSuccess} = useAlerts()
  const {party, isLoaded} = useProfile()
  const [partyMembers, setPartyMembers] = useState<IManagedPartyMember[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const urlPrefix = validRolesUrlPrefixes[party?.partyRole]

  const handleDisableUser = async (asPartyId: number, managedPartyMember: IManagedPartyMember) => {
    try {
      const updatedPartyMember = await contentService.disablePartyMember(asPartyId, managedPartyMember.id)

      const index = partyMembers.findIndex(partyMember => partyMember.id === updatedPartyMember.id)
      const partyMembersCopy = [...partyMembers]
      partyMembersCopy[index] = {...partyMembersCopy[index], user: toManagedUser(updatedPartyMember.user)}

      setPartyMembers(partyMembersCopy)
      addSuccess(translate('User suspended') + '.')
    } catch (err) {
      console.error(err)
      addError(translate('Failed to suspend user') + '.', err?.correlationId, err?.message)
    }
  }

  const handleResendInvite = async (asPartyId: number, managedPartyMember: IManagedPartyMember) => {
    try {
      await contentService.resendUserInvite(asPartyId, managedPartyMember.id)
      addSuccess(translate('Invite resent') + '.')
    } catch (err) {
      console.error(err)
      addError(translate('Failed to resend invite') + '.', err?.correlationId, err?.message)
    }
  }

  const handleEnableUser = async (asPartyId: number, managedPartyMember: IManagedPartyMember) => {
    try {
      const partyMember = await contentService.enablePartyMember(asPartyId, managedPartyMember.id)

      const index = partyMembers.findIndex(partyMember => partyMember.id === managedPartyMember.id)
      const partyMembersCopy = [...partyMembers]
      partyMembersCopy[index] = partyMemberToManaged(partyMember)

      setPartyMembers(partyMembersCopy)
      addSuccess(translate('User activated') + '.')
    } catch (err) {
      console.error(err)
      addError(translate('Failed to activate user') + '.', err?.correlationId, err?.message)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (party) {
      contentService.getPartyMembers(party.id).then(partyMembers => {
        setPartyMembers(partyMembers.map(partyMember => partyMemberToManaged(partyMember)))
        setIsLoading(false)
      })
    }
  }, [contentService, party?.id])

  if (isLoaded && !validRolesUrlPrefixes[party?.partyRole]) {
    return <Redirect to={getPlatformUrl(party?.partyRole, theme)} />
  }

  return (
    <>
      <AccountPage
        corner={
          <Link to={`${urlPrefix}/company/members/add`}>
            <Button margin={{top: 1}}>{translate('Add')}</Button>
          </Link>
        }
        title={`${party?.name} users`}
        description={translate('Manage your company users')}
        verticalGap={0}
      >
        <ManagePartyMembersTable
          urlPrefix={urlPrefix}
          partyMembers={partyMembers}
          loading={isLoading}
          handleResendInvite={handleResendInvite}
          handleEnableUser={handleEnableUser}
          handleDisableUser={handleDisableUser}
        />
      </AccountPage>
    </>
  )
}

export default ManageUsers
