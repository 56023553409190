import useTheme from 'hooks/useTheme'
import React, {useState} from 'react'
import styled from 'styled-components'
import CloseButton from './CloseButton'
import {Alert} from '@mui/material'

export const NotificationContainer = styled(Alert)`
  z-index: 1;
  position: fixed;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  padding: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 -1px 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledCloseButton = styled(CloseButton)`
  margin-left: auto;
`

interface IProps extends React.PropsWithChildren {
  onClose: () => void
}

const GlobalNotificationBar: React.FC<IProps> = ({children, onClose}) => {
  const theme = useTheme()
  const [isActive, setIsActive] = useState(true)

  const handleClose = () => {
    onClose()
    setIsActive(false)
  }

  if (!isActive) {
    return null
  }

  return (
    <NotificationContainer severity="warning" color="warning" onClose={handleClose}>
      {children}
    </NotificationContainer>
  )
}

export default GlobalNotificationBar
