import React, {useEffect, useMemo} from 'react'
import Table, {TableValue} from 'components/Table'
import Text from 'components/Text'
import Box from 'components/Box'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {IAggrSpotPriceInterval} from 'domain/IMarketIntelligence'
import {getMonthName} from 'helpers/date'
import {getHumanReadableCountry} from 'helpers/location'
import Flag from 'react-world-flags'
import {getPriceUnitSymbol, getSlashEnergyUnit} from 'helpers/price'
import {useAtomValue, useSetAtom} from 'jotai'
import {aggrSpotPricesAtom, mapDataAtom} from 'atoms/marketIntelligence'
import {Country} from 'domain/ILocation'
import {periodAtom, PeriodType} from 'components/PeriodSelect/periodSelect'
import {calculatedAvgPricesByCountry} from './SpotPrice'
import Rain from 'components/Icons/Rain'

const AggrSpotTable = () => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const setMapData = useSetAtom(mapDataAtom)
  const period = useAtomValue(periodAtom)
  const {data, loading} = useAtomValue(aggrSpotPricesAtom)
  const {intervals, uiError} = data || {}
  const countriesWithPriceUnit =
    useMemo(
      () =>
        intervals?.[0].prices.reduce((acc, priceObj) => {
          acc[priceObj.country] = priceObj.priceUnit
          return acc
        }, {}),
      [intervals],
    ) || {}
  const allCountries = Object.keys(countriesWithPriceUnit) || []
  const avgPricesByCountry = useMemo(() => calculatedAvgPricesByCountry(intervals || []), [intervals])
  const isMonthly = period.period === PeriodType.YEAR

  useEffect(() => {
    if (!loading) {
      setMapData(
        Object.entries(avgPricesByCountry).map(([country, price]) => ({
          price,
          country,
          priceUnit: countriesWithPriceUnit[country],
        })),
      )
    }
  }, [intervals, loading])

  if (uiError) {
    return (
      <Box align="center" direction="column">
        <Rain />
        <Text size="mlarge">{translate(uiError)}</Text>
      </Box>
    )
  }

  return (
    <Table<IAggrSpotPriceInterval>
      tableName={translate('Spot price')}
      highlightOnHover
      isLoading={loading}
      data={intervals}
      columns={[
        {
          title: translate(isMonthly ? 'Month' : 'Day of month'),
          accessor: interval => interval.interval,
          render: (date: number) => (
            <Text size="medium" nowrap font={theme.font.numbers}>
              {isMonthly ? getMonthName(date - 1) : date}
            </Text>
          ),
        },
        ...allCountries.map((country: Country) => ({
          sortable: false,
          title: (
            <Box direction="row" align="center" gap={1}>
              <Flag height={12} width={20} code={country} />
              <Text style={{lineHeight: '100%'}} size="msmall" font={theme.font.headings} margin={{top: 0.5}}>
                {getHumanReadableCountry(country, translate)}
              </Text>
            </Box>
          ),
          accessor: interval => interval.prices.find(p => p.country === country),
          render: item => (
            <TableValue
              nowrap
              prefix={item.price ? `${getPriceUnitSymbol(item.priceUnit)}` : ''}
              value={item.price}
              unit={item.price ? getSlashEnergyUnit(item.priceUnit) : ''}
            />
          ),
        })),
      ]}
    />
  )
}

export default AggrSpotTable
