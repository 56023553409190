import React from 'react'
import {Grid, Divider, Popover as MuiPopover} from '@mui/material'
import Box from 'components/Box'
import ExpandButton from 'components/IconButton/ExpandButton'
import {PeriodType, QUARTERS, MONTHS, IPeriod, yearAtom, PeriodValue} from './periodSelect'
import {InputWrap} from 'components/Input'
import ITheme from 'theme/ITheme'
import useLocalization from 'hooks/useLocalization'
import styled from 'styled-components'
import {useAtomValue} from 'jotai'
import {getCurrentMonth, getCurrentYear} from 'helpers/date'

const StyledInputWrap = styled(InputWrap)<{open?: boolean}>`
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;
  border-color: ${props => (props.open ? props.theme.colors.primary : props.theme.colors.lightGrey)};
  width: 240px;
  height: 32px;
  margin: 0;

  input:disabled {
    color: ${props => props.theme.colors.secondary};
  }
`

const StyledItem = styled(Box)<{selected?: boolean; disabled?: boolean}>`
  padding: 8px 16px;
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: left;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  font-size: 14px;
  line-height: 18px;
  font-weight: ${props => (props.selected ? 500 : 200)};
  font-family: ${props => (props.theme as ITheme).font.secondaryFont};
  background-color: ${props => (props.selected ? props.theme.colors.outline : 'transparent')};
  background-color: ${props => props.disabled && props.theme.colors.nGrey3};

  &:hover {
    background-color: ${props => (props.theme as ITheme).colors.outline};
    background-color: ${props => props.disabled && props.theme.colors.nGrey3};
  }
`

interface IProps extends React.PropsWithChildren {
  open: boolean
  setAnchorEl: (anchorEl: HTMLElement | null) => void
  anchorEl: HTMLElement | null
  selected: IPeriod
  handleChange: (period: PeriodType, value?: string) => void
  hideCustom?: boolean
  hideQuarter?: boolean
  hideWholeYear?: boolean
}

const Period: React.FC<IProps> = ({
  open,
  setAnchorEl,
  anchorEl,
  selected,
  handleChange,
  hideCustom,
  hideQuarter,
  hideWholeYear,
}) => {
  const {translate} = useLocalization()
  const year = useAtomValue(yearAtom)
  const isQuickLink = selected.period === PeriodType.QUICK_LINK

  const isFutureMonth = (month: string) => {
    const currentYear = getCurrentYear()
    const currentMonth = getCurrentMonth()
    const monthIndex = MONTHS.indexOf(month)

    return year === currentYear && monthIndex > currentMonth
  }

  return (
    <>
      <StyledInputWrap open={open} onClick={e => setAnchorEl(e.currentTarget)}>
        <Box fillHeight width="100%" style={{lineHeight: '100%'}}>
          {isQuickLink ? '' : selected.value}
        </Box>
        <ExpandButton expanded={open} />
      </StyledInputWrap>
      <MuiPopover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            elevation: 0,
          },
        }}
      >
        <Box round={0.5} border width={30} pad={0.5}>
          <Grid container spacing={0.5}>
            {!hideWholeYear && (
              <Grid item xs={12}>
                <StyledItem
                  onClick={() => handleChange(PeriodType.YEAR, translate(PeriodValue.WHOLE_YEAR))}
                  selected={selected.period === PeriodType.YEAR}
                >
                  {translate(PeriodValue.WHOLE_YEAR)}
                </StyledItem>
              </Grid>
            )}
            {!hideQuarter && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            {!hideQuarter &&
              QUARTERS.map(quarter => (
                <Grid item xs={6} key={quarter}>
                  <StyledItem
                    onClick={() => handleChange(PeriodType.QUARTER, quarter)}
                    selected={selected.period === PeriodType.QUARTER && selected.value === quarter}
                  >
                    {quarter}
                  </StyledItem>
                </Grid>
              ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {MONTHS.map(month => {
              const disabled = isFutureMonth(month)

              return (
                <Grid item xs={6} key={month}>
                  <StyledItem
                    onClick={() => !disabled && handleChange(PeriodType.MONTH, month)}
                    selected={selected.period === PeriodType.MONTH && selected.value === month}
                    disabled={disabled}
                  >
                    {translate(month)}
                  </StyledItem>
                </Grid>
              )
            })}
            {!hideCustom && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <StyledItem
                    onClick={() => handleChange(PeriodType.CUSTOM, translate(PeriodValue.CUSTOM_PERIOD))}
                    selected={selected.period === PeriodType.CUSTOM}
                  >
                    {translate(PeriodValue.CUSTOM_PERIOD)}
                  </StyledItem>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </MuiPopover>
    </>
  )
}

export default Period
