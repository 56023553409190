import React, {useEffect, useMemo} from 'react'
import Table, {TableValue} from 'components/Table'
import Text from 'components/Text'
import Box from 'components/Box'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {ISpotPriceInterval} from 'domain/IMarketIntelligence'
import {getHourlyInterval} from 'helpers/date'
import {getHumanReadableCountry} from 'helpers/location'
import Flag from 'react-world-flags'
import {getPriceUnitSymbol, getSlashEnergyUnit} from 'helpers/price'
import {useAtomValue, useSetAtom} from 'jotai'
import {mapDataAtom, rawSpotPricesAtom} from 'atoms/marketIntelligence'
import {Country} from 'domain/ILocation'
import {calculatedAvgPricesByCountry} from './SpotPrice'

const RawSpotTable = () => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const setMapData = useSetAtom(mapDataAtom)
  const {data: spotPrices, loading} = useAtomValue(rawSpotPricesAtom)
  const countriesWithPriceUnit =
    useMemo(
      () =>
        spotPrices?.intervals[0].prices.reduce((acc, priceObj) => {
          acc[priceObj.country] = priceObj.priceUnit
          return acc
        }, {}),
      [spotPrices],
    ) || {}
  const allCountries = Object.keys(countriesWithPriceUnit) || []
  const avgPricesByCountry = useMemo(() => calculatedAvgPricesByCountry(spotPrices?.intervals || []), [spotPrices])

  useEffect(() => {
    if (!loading) {
      setMapData(
        Object.entries(avgPricesByCountry).map(([country, price]) => ({
          price,
          country,
          priceUnit: countriesWithPriceUnit[country],
        })),
      )
    }
  }, [spotPrices, loading])

  return (
    <Table<ISpotPriceInterval>
      tableName={translate('Spot price')}
      highlightOnHover
      isLoading={loading}
      data={spotPrices?.intervals}
      columns={[
        {
          title: translate('Hour'),
          accessor: interval => interval.name,
          render: (date: string) => (
            <Text size="medium" nowrap font={theme.font.numbers}>
              {getHourlyInterval(date)}
            </Text>
          ),
        },
        ...allCountries.map((country: Country) => ({
          sortable: false,
          title: (
            <Box direction="row" align="center" gap={1}>
              <Flag height={12} width={20} code={country} />
              <Text style={{lineHeight: '100%'}} size="msmall" font={theme.font.headings} margin={{top: 0.5}}>
                {getHumanReadableCountry(country, translate)}
              </Text>
            </Box>
          ),
          accessor: interval => interval.prices.find(p => p.country === country),
          render: item => (
            <TableValue
              nowrap
              prefix={item.price ? `${getPriceUnitSymbol(item.priceUnit)}` : ''}
              value={item.price}
              unit={item.price ? getSlashEnergyUnit(item.priceUnit) : ''}
            />
          ),
        })),
      ]}
    />
  )
}

export default RawSpotTable
