export enum Country {
  ALB = 'ALB',
  AUT = 'AUT',
  BEL = 'BEL',
  BIH = 'BIH',
  BGR = 'BGR',
  CHE = 'CHE',
  CYP = 'CYP',
  CZE = 'CZE',
  HRV = 'HRV',
  DEU = 'DEU',
  DNK = 'DNK',
  ESP = 'ESP',
  EST = 'EST',
  FIN = 'FIN',
  FRA = 'FRA',
  GEO = 'GEO',
  GRC = 'GRC',
  HUN = 'HUN',
  IRL = 'IRL',
  ISL = 'ISL',
  ITA = 'ITA',
  LTU = 'LTU',
  LVA = 'LVA',
  LUX = 'LUX',
  MNE = 'MNE',
  NLD = 'NLD',
  NOR = 'NOR',
  PRT = 'PRT',
  SRB = 'SRB',
  SVN = 'SVN',
  SWE = 'SWE',
  UKR = 'UKR',
  GBR = 'GBR',
  ROU = 'ROU',
}

export enum StateOfAustralia {
  NSW = 'NSW',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

export default interface ILocation {
  id: number
  addressStreet: string
  addressCity: string
  addressPostCode: string
  addressCountry: Country
  addressSubdivision?: string
  coordinatesLat: number
  coordinatesLon: number
  details?: string
  placeId: string
  searchString?: string
}
