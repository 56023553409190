import React from 'react'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import Button from 'components/Button'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import {Container} from '../Layout'
import {useAtom, useAtomValue} from 'jotai'
import VerticalStack from 'components/VerticalStack'
import {partyAtom} from 'atoms/party'
import {sellerOnboardingAtom, sellerSiteDashboardAtom} from 'atoms/onboarding'
import {OnboardingStatus} from 'domain/IParty'
import Heading from 'components/Heading'
import {formatDecimal} from 'helpers/format'
import DashboardLoadingSkeleton from 'components/Platform/Portfolio/Site/DashboardLoadingSkeleton'
import useServices from 'hooks/useServices'
import {useHistory} from 'react-router-dom'
import SiteSummaryChart from '../SIteSummaryChart'
import CenteredLoader from 'components/CenteredLoader'
import useAlerts from 'hooks/useAlerts'

interface IProps extends React.PropsWithChildren {
  openSkipDialog: () => void
}

const HeroContainer: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <Container>
      <Box justify="center" align="center" pad={{top: 15}}>
        {children}
      </Box>
    </Container>
  )
}

const ReviewProduct: React.FC<IProps> = ({openSkipDialog}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const [party, setParty] = useAtom(partyAtom)
  const {addError, addSuccess} = useAlerts()
  const {v2ContentService} = useServices()
  const history = useHistory()
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(sellerOnboardingAtom)
  const {data: siteDashboard, loading: siteDashbdLoading, error: siteDashbError} = useAtomValue(sellerSiteDashboardAtom)

  const handlePublish = async () => {
    try {
      const res = await v2ContentService.publishOnboardingProduct(party.id)
      const updated = {
        ...party,
        onboardingStatus: res.onboardingStatus,
      }
      setParty(updated)

      history.push('/broker/portfolio/products')
      addSuccess(translate('Your product is now on the marketplace. Congratulations!'))
    } catch (ex) {
      addError('Failed to publish product', ex?.correlationId, ex?.message)
    }
  }

  if (onbError) {
    return (
      <HeroContainer>
        <Heading size="xlarge">Something went wrong</Heading>
      </HeroContainer>
    )
  }

  if (onbLoading) {
    return (
      <HeroContainer>
        <CenteredLoader size="medium" color={theme.colors.common.white} />
      </HeroContainer>
    )
  }

  if (onboardResult.onboardingStatus !== OnboardingStatus.SELLER_PRODUCT_CREATED) {
    if (onboardResult.onboardingStatus === OnboardingStatus.COMPLETED) {
      return (
        <HeroContainer>
          <Heading size="xlarge">Product is already published</Heading>
        </HeroContainer>
      )
    }
    console.error(`Invalid onboarding state ${onboardResult.onboardingStatus}`)
    // TODO: redirect to correct screen for the given status?
    return (
      <HeroContainer>
        <Heading size="xlarge">You need to complete the other steps first</Heading>
      </HeroContainer>
    )
  }

  const product = onboardResult.product
  const site = onboardResult.site

  return (
    <Container>
      <Text size="xxxlarge" color={theme.colors.common.black} margin={{bottom: 2}}>
        3. Review and publish
      </Text>
      <VerticalStack margin={{top: 1}}>
        <Text uppercase> {translate('Product name')} </Text>
        <Text bold data-cy="name">
          {product.name}
        </Text>
      </VerticalStack>

      <VerticalStack margin={{top: 1}}>
        <Text uppercase> {translate('Description')} </Text>
        <Text bold data-cy="description">
          {product.description}
        </Text>
      </VerticalStack>

      <VerticalStack margin={{top: 1}}>
        <Text uppercase> {translate('Smart meter ID')} </Text>
        <Text bold data-cy="meterRef">
          {site.meterReference}
        </Text>
      </VerticalStack>

      <VerticalStack margin={{top: 1}}>
        <Text uppercase> {translate(`Price, ${product.currency}/MWh`)} </Text>
        <Text bold data-cy="price">
          {formatDecimal(product.price)}
        </Text>
      </VerticalStack>

      <Box
        margin={{top: 3}}
        pad={2}
        round={2}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
        }}
      >
        {siteDashbdLoading ? (
          <DashboardLoadingSkeleton />
        ) : siteDashbError ? (
          <Heading size="medium">Failed to load site summary chart</Heading>
        ) : (
          <SiteSummaryChart timeseries={siteDashboard.timeseries} siteName={siteDashboard.siteName} />
        )}
      </Box>

      <HorizStack justify="space-between" margin={{top: 4}}>
        <Button large variant="outlined" onClick={openSkipDialog} contrast data-cy="skip">
          <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
            {translate('Skip')}
          </Text>
        </Button>
        <Button onClick={handlePublish} variant="outlined" contrast large data-cy="publish">
          <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
            {translate('Publish now')}
          </Text>
        </Button>
      </HorizStack>
    </Container>
  )
}

export default ReviewProduct
